import kaffeebgImage from './../assets/imgs/types/kaffeevollautomat/bg.jpeg'


var getAllConfigs = async function(){

    let aufzugswartungConfig = {
        'html_title': 'Einfach zum Angebot',
        'type': 'AUFZUGSWARTUNG',
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.',
        ],
        'logo' : new URL('./../assets/imgs/types/aufzugswartung/logo.png?width=300', import.meta.url),
        'bg_image' : new URL('./../assets/imgs/types/aufzugswartung/bg.png?width=2000&format=jpeg', import.meta.url),
        'bg_pos': 'center bottom',
        'bg_isDark': true,
        'head_right' : [
            '<div class="lead-counter">Bereits <span>51.384</span><br/> Anfragen vermittelt</div>',
            '<div class="lead-counter">Mehr als <span>200</span><br/> Anbieter</div>'
        ],
        'steps': [
            {
                'component': 'Stepper',
                'title': 'Vergleichsangebote für Aufzugswartung erhalten',
                'subtitle': 'Bis zu 4 regionale Angebote vergleichen & Kosten sparen*',
                'steps': [
                    {
                        'step_type': 'image',
                        'title': 'Wie hoch ist die Tragfähigkeit des Aufzugs?',
                        'key': 'tragfaehigkeit',
                        'multiple': false,
                        'choices': [
                            {
                                'value': '450kg oder weniger',
                                'text': '450kg oder weniger',
                                'icon': new URL('./../assets/imgs/types/aufzugswartung/icons/450kg_oder_weniger.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value': '450kg bis 630kg',
                                'text': '450kg bis 630kg',
                                'icon': new URL('./../assets/imgs/types/aufzugswartung/icons/450kg_bis_630kg.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value': '630kg bis 1000kg',
                                'text': '630kg bis 1000kg',
                                'icon': new URL('./../assets/imgs/types/aufzugswartung/icons/630kg_bis_1000kg.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value': '1000kg oder mehr',
                                'text': '1000kg oder mehr',
                                'icon': new URL('./../assets/imgs/types/aufzugswartung/icons/1000kg_oder_mehr.png?width=200&format=png', import.meta.url)
                            },
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title': 'Von welchen Dienstleistungen möchten Sie profitieren?',
                        'key': 'dienstleistungen',
                        'multiple': false,
                        'choices': [
                            {
                                'value': 'Aufzugswartung',
                                'text': 'Aufzugswartung',
                                'icon': new URL('./../assets/imgs/types/aufzugswartung/icons/aufzugswartung.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value': 'Modernisierung',
                                'text': 'Modernisierung',
                                'icon': new URL('./../assets/imgs/types/aufzugswartung/icons/modernisierung.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value': 'Notrufsystem',
                                'text': 'Notrufsystem',
                                'icon': new URL('./../assets/imgs/types/aufzugswartung/icons/notrufsystem.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value': 'Neubau',
                                'text': 'Neubau',
                                'icon': new URL('./../assets/imgs/types/aufzugswartung/icons/neubau.png?width=200&format=png', import.meta.url)
                            },
                        ],
                    },
                    {
                        'step_type': 'slider',
                        'title': 'Welches Baujahr hat der Aufzug?',
                        'key': 'aufzug_baujahr',
                        'min': 1930,
                        'max': 2024,
                        'value': 1980,
                        'step': 5,
                        'append_value': ''
                    },
                    {
                        'component': 'TextInputStep',
                        'title': 'Wer ist der Hersteller des Aufzugs?',
                        'button': {
                            'text': 'Nächste Seite',
                        },
                        'key': 'hersteller',
                    },
                    {
                        'step_type': 'slider',
                        'title': 'Wie viele Haltestellen hat der Aufzug?',
                        'key': 'aufzug_haltestellen',
                        'min': 1,
                        'max': 12,
                        'value': 3,
                        'step': 1,
                        'append_value': ''
                    },
                ]
            },
        {
            'component': 'ZipStep'
        },
        {
            'component': 'SearchStep'
        },
            {
                'component': 'DataStep',
                'title': 'Wer soll die Angebote erhalten?',
                'fields': [
                    'name',
                    'street',
                    'email',
                    'telefon'
                ],
                'btn_title' : 'Vergleichen & sparen',
                'btn_subtitle' : 'kostenlos & unverbindlich',
            },
            {
                'component': 'AfterSave',
                'cross_selling' : false,
            },
        ],

        };

    var wasserspenderConfig = {
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.',
            '**Der Vergleich zwischen leitungsgebundenen Wasserspender und Wasserflasche basiert auf folgender Berechnung: Wasserverbrauch für 18 Personen, 1,5 Liter pro Tag, 20 Arbeitstage pro Monat, was 540 Liter pro Monat ergibt; Die Betriebskosten des leitungsgebundenen Wasserspenders i.H.v. Leitungswasser und Strom 0,02 Euro/Liter; Betriebskosten für die Bereitstellung von Wasserflaschen i. H. v. 0,52 Euro pro Liter Wasser (der hypothetische Preis pro 12-Liter-Kiste beträgt 6 Euro, Mengenrabatt i. H. v. 15%, Lagerung berücksichtigt den Gewerbe-Quadratmeterpreis von Berlin i. H. v. 26 Euro, keine Versandkosten; Wasserspender Miete/Abschreibung (über 60 Monate) i. H. v. 100 Euro pro Monat, inklusive Wartung und Filterwechsel.'
        ],
        'logo' : 'imgs/types/wasserspender/general/default/wasserspender-logo.png',
        'bg_image' : 'imgs/types/wasserspender/general/default/bg.jpeg',
        'bg_pos': 'center bottom',


        'head_right' : [
            '<div class="lead-counter">Bereits <span>51.384</span><br/> Anfragen vermittelt</div>',
            '<div class="lead-counter">Mehr als <span>200</span><br/> Anbieter</div>'
        ],




        //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
        'steps': [




            {
                'component': 'Stepper',
                'title' : 'Wasserspender für <u>Büro & Praxis</u>',
                'subtitle' : 'Kostenlos & unverbindlich Angebote von passenden Anbietern vergleichen und sparen*',
                'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
                'steps' : [

                    {
                        'step_type': 'image',
                        'title' : 'Welche Art von Wasser bevorzugen Sie?',
                        'key' : 'angebot',
                        'multiple' : true,
                        'choices' : [
                            {
                                'value' : 'Stilles Wasser',
                                'text' : 'Stilles Wasser',
                                'icon' :  'imgs/types/wasserspender/icons/default/still-water.png'
                            },
                            {
                                'value' : 'Gekühltes Wasser',
                                'text' : 'Gekühltes Wasser',
                                'icon' :  'imgs/types/wasserspender/icons/default/cold-water.png'
                            },
                            {
                                'value' : 'Mit Kohlensäure',
                                'text' : 'Mit Kohlensäure',
                                'icon' :  'imgs/types/wasserspender/icons/default/carbonate-water.png'
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  'imgs/types/wasserspender/icons/default/water-dontknow.png'
                            },
                        ],
                    },

                    {
                        'step_type': 'image',
                        'title' : 'Wie viele Geräte benötigen Sie?',
                        'key' : 'anzahl',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : '1',
                                'text' : '1',
                                'icon' :  'imgs/types/wasserspender/icons/default/nos-one.png'
                            },
                            {
                                'value' : '2 - 5',
                                'text' : '2 - 5',
                                'icon' :  'imgs/types/wasserspender/icons/default/nos-one-five.png'
                            },
                            {
                                'value' : 'Mehr als 5',
                                'text' : 'Mehr als 5',
                                'icon' :  'imgs/types/wasserspender/icons/default/nos-five-plus.png'
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  'imgs/types/wasserspender/icons/default/nos-dontknow.png'
                            },
                        ],
                    },
                ]
            },
            {
                'component': 'ZipStep'
            },
            {
                'component': 'SearchStep'
            },
            {
                'component': 'DataStep'
            },






        ],

    };


    let kaffeevollautomatConfigAT = {
        'html_title': 'Kaffeevollautomaten Anbieter vergleichen und sparen | Kaffeevollautomaten-Online.at',
        'type': 'KAFFEEVOLLAUTOMAT',
        'target_country': 'AT',
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.'        ],
        'logo' : new URL('./../assets/imgs/types/kaffeevollautomat/logoAT.png?width=300&format=jpeg', import.meta.url),
        'bg_image' : new URL('./../assets/imgs/types/kaffeevollautomat/bg.jpeg?width=2000&format=jpeg', import.meta.url),
        'bg_pos': 'center bottom',
        'bg_isDark': true,
        'head_right' : [
            '<div class="lead-counter">Bereits <span>51.384</span><br/> Anfragen vermittelt</div>',
            '<div class="lead-counter">Mehr als <span>200</span><br/> Anbieter</div>'
        ],




        //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
        'steps': [




            {
                'component': 'Stepper',
                'title' : 'Kaffeevollautomaten für <u>Büro & Praxis</u>',
                'subtitle' : 'Vergleichen Sie Angebote aus Ihrer Region kostenlos & unverbindlich. *',
                //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
                'steps' : [

                    {
                        'step_type': 'image',
                        'title' : 'Welche Art von Gerät bevorzugen Sie?',
                        'key' : 'art',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Tischgerät',
                                'text' : 'Tischgerät',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/1.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Standgerät',
                                'text' : 'Standgerät',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/2.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/3.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wie viele Tassen pro Tag benötigen Sie?',
                        'key' : 'tassen_pro_tag',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : '10 bis 20',
                                'text' : '10 bis 20',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/8.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '20 bis 50',
                                'text' : '20 bis 50',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/9.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '50 bis 100',
                                'text' : '50 bis 100',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/10.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'mehr als 100',
                                'text' : 'mehr als 100',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/11.png?width=200&format=png', import.meta.url)
                            },
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wie viele Geräte benötigen Sie?',
                        'key' : 'anzahl_geraete',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : '1',
                                'text' : '1',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/4.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '2',
                                'text' : '2',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/5.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '3',
                                'text' : '3',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/6.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/3.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wie umfangreich soll das Getränkesortiment sein?',
                        'key' : 'getraenkesortiment',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Minimal',
                                'text' : 'Minimal',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/12.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Standard',
                                'text' : 'Standard',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/13.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Umfangreich',
                                'text' : 'Umfangreich',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/14.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/3.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wo soll der Kaffeevollautomat Verwendung finden? ',
                        'key' : 'verwendung',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Kleingewerbe',
                                'text' : 'Kleingewerbe',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/18.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Gastronomie / Hotel',
                                'text' : 'Gastronomie / Hotel',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/17.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Büro',
                                'text' : 'Büro',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/16.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Privatgebrauch',
                                'text' : 'Privatgebrauch',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/3.png?width=200&format=png', import.meta.url),
                                'href' : 'https://kaffeevollautomaten-online.de/shop'
                            }
                        ],
                    },
                ]
            },
            {
                'component': 'ZipStep'
            },
            {
                'component': 'SearchStep',
                'subtitle': 'Bitte haben Sie einen Augenblick Geduld.'
            },
            {
                'component': 'DataStep',
                'title': 'Wer soll die Angebote erhalten?',
                'fields': [
                    'firma',
                    'name',
                    'street',
                    'email',
                    'telefon'
                ],
                'btn_title' : 'Vergleichen & sparen',
                'btn_subtitle' : 'kostenlos & unverbindlich',
            },
            {
                'component': 'AfterSave',
                'cross_selling' : true,
                'cross_selling_options': {
                    'title': 'Sind Sie auch an einem der folgenden Produkte für Ihr Gewerbe interessiert?',
                    'ad_type': 'AdImageBox',
                    'number_of_ads': 4,
                    'ad_items': [
                        {
                            'text': 'Snack-/Getränkeautomat',
                            'image': new URL('./../assets/imgs/cross_selling/snackautomat.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Snackautomat',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Wasserspender',
                            'image': new URL('./../assets/imgs/cross_selling/wasserspender.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Wasserspender',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Mitarbeitergewinnung',
                            'image': new URL('./../assets/imgs/cross_selling/mitarbeitergewinnung.png?width=300&format=jpeg', import.meta.url),
                            'value': 'Mitarbeitergewinnung',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Leadgenerierung',
                            'image': new URL('./../assets/imgs/cross_selling/leadgenerierung.png?width=300&format=jpeg', import.meta.url),
                            'value': 'Leadgenerierung',
                            'text_position': 'bottom',
                        },
                    ]
                },
            },






        ],

    };

    let kaffeevollautomatConfig = {
        'html_title': 'Kaffeevollautomaten Anbieter vergleichen und sparen | Kaffeevollautomaten-Online.de',
        'type': 'KAFFEEVOLLAUTOMAT',
        'target_country': 'DE',
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.'        ],
        'logo' : new URL('./../assets/imgs/types/kaffeevollautomat/logo.png?width=300&format=jpeg', import.meta.url),
        'bg_image' : new URL('./../assets/imgs/types/kaffeevollautomat/bg.jpeg?width=2000&format=jpeg', import.meta.url),
        'bg_pos': 'center bottom',
        'bg_isDark': true,
        'head_right' : [
            '<div class="lead-counter">Bereits <span>51.384</span><br/> Anfragen vermittelt</div>',
            '<div class="lead-counter">Mehr als <span>200</span><br/> Anbieter</div>'
        ],




        //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
        'steps': [


            {
                'component': 'Stepper',
                'title' : 'Kaffeevollautomaten für <u>Büro & Praxis</u>',
                'subtitle' : 'Vergleichen Sie Angebote aus Ihrer Region kostenlos & unverbindlich. *',
                //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
                'steps' : [
                    {
                        'step_type': 'image',
                        'title' : 'Welche Art von Gerät bevorzugen Sie?',
                        'key' : 'art',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Tischgerät',
                                'text' : 'Tischgerät',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/1.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Standgerät',
                                'text' : 'Standgerät',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/2.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/3.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wie viele Tassen pro Tag benötigen Sie?',
                        'key' : 'tassen_pro_tag',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : '10 bis 20',
                                'text' : '10 bis 20',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/8.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '20 bis 50',
                                'text' : '20 bis 50',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/9.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '50 bis 100',
                                'text' : '50 bis 100',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/10.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'mehr als 100',
                                'text' : 'mehr als 100',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/11.png?width=200&format=png', import.meta.url)
                            },
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wie viele Geräte benötigen Sie?',
                        'key' : 'anzahl_geraete',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : '1',
                                'text' : '1',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/4.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '2',
                                'text' : '2',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/5.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '3',
                                'text' : '3',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/6.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/3.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wie umfangreich soll das Getränkesortiment sein?',
                        'key' : 'getraenkesortiment',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Minimal',
                                'text' : 'Minimal',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/12.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Standard',
                                'text' : 'Standard',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/13.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Umfangreich',
                                'text' : 'Umfangreich',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/14.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/3.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wo soll der Kaffeevollautomat Verwendung finden?',
                        'key' : 'verwendung',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Kleingewerbe',
                                'text' : 'Kleingewerbe',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/18.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Gastronomie / Hotel',
                                'text' : 'Gastronomie / Hotel',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/17.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Büro',
                                'text' : 'Büro & Dienstleister',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/16.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Privatgebrauch',
                                'text' : 'Privatgebrauch',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/3.png?width=200&format=png', import.meta.url),
                            }
                        ],
                    },
                    {
                        'component': 'InfoStep',
                        'text': 'Leider richtet sich unser Angebotsservice nur an Firmenkunden. Wir können keine Privatanfragen vermitteln. Falls Sie einen Kaffeevollautomaten für den Privatgebrauch kaufen möchten, klicken Sie hier:',
                        'button': {
                            'text': 'Zum Kaffeevollautomaten Shop',
                            'href': 'https://kaffeevollautomaten-online.de/shop'
                        },
                        'condition':  {
                            'key' : 'verwendung',
                            'value' : 'Privatgebrauch'
                        },
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Handelt es sich bei Ihrer Firma um eine Neugründung?',
                        'key' : 'neugruendung',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Nein',
                                'text' : 'Nein',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/19.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '6 - 12 Monate',
                                'text' : 'Ja, 6 - 12 Monate alt',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/21.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '1 - 6 Monate',
                                'text' : 'Ja, 1 - 6 Monate alt',
                                'icon' :  new URL('./../assets/imgs/types/kaffeevollautomat/icons/20.png?width=200&format=png', import.meta.url)
                            },
                        ],
                    },
                ]
            },
            {
                'component': 'ZipStep'
            },
            {
                'component': 'SearchStep',
                'subtitle': 'Bitte haben Sie einen Augenblick Geduld.'
            },
            {
                'component': 'DataStep',
                'title': 'Wer soll die Angebote erhalten?',
                'fields': [
                    'firma',
                    'name',
                    'street',
                    'email',
                    'telefon'
                ],
                'btn_title' : 'Vergleichen & sparen',
                'btn_subtitle' : 'kostenlos & unverbindlich',
            },
            {
                'component': 'AfterSave',
                'cross_selling' : true,
                'cross_selling_options': {
                    'title': 'Sind Sie auch an einem der folgenden Produkte für Ihr Gewerbe interessiert?',
                    'ad_type': 'AdImageBox',
                    'number_of_ads': 4,
                    'ad_items': [
                        {
                            'text': 'Snack-/Getränkeautomat',
                            'image': new URL('./../assets/imgs/cross_selling/snackautomat.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Snackautomat',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Wasserspender',
                            'image': new URL('./../assets/imgs/cross_selling/wasserspender.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Wasserspender',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Mitarbeitergewinnung',
                            'image': new URL('./../assets/imgs/cross_selling/mitarbeitergewinnung.png?width=300&format=jpeg', import.meta.url),
                            'value': 'Mitarbeitergewinnung',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Leadgenerierung',
                            'image': new URL('./../assets/imgs/cross_selling/leadgenerierung.png?width=300&format=jpeg', import.meta.url),
                            'value': 'Leadgenerierung',
                            'text_position': 'bottom',
                        },
                    ]
                },
            },






        ],

    };

    let terrassendachConfig = {
        'html_title': 'Terrassenüberdachungen vergleichen und sparen | Terrassendach-Vergleich.de',
        'type': 'TERRASSENDACH',
        'target_country': 'DE',
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.'        ],
        'logo' : new URL('./../assets/imgs/types/terrassendach/terrassendach-logo.png?width=300', import.meta.url),
        'bg_image' : new URL('./../assets/imgs/types/terrassendach/bg.jpg?width=2000&format=jpeg', import.meta.url),
        'bg_pos': 'center bottom',
        'bg_isDark': true,
        'head_right' : [
            '<div class="lead-counter">Bereits <span>51.384</span><br/> Anfragen vermittelt</div>',
            '<div class="lead-counter">Mehr als <span>200</span><br/> Anbieter</div>'
        ],
        //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
        'steps': [
            {
                'component': 'Stepper',
                'title' : 'Bis zu 3 regionale Angebote',
                'subtitle' : 'Jetzt Angebote von lokalen Terrassendach Anbietern vergleichen und sparen!*',
                'steps' : [

                    {
                        'step_type': 'image',
                        'title' : 'Wie groß ist die Terrasse?',
                        'key' : 'groesse',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Bis 15 m²',
                                'text' : 'Bis 15 m²',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/01-01.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Zwischen 15 und 30m²',
                                'text' : 'Zwischen 15 und 30m²',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/01-02.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Mehr als 30 m²',
                                'text' : 'Mehr als 30 m²',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/01-03.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Welches Material bevorzugen Sie?',
                        'key' : 'materialart',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Aluminium',
                                'text' : 'Aluminium (empfohlen)',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/02-01.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Holz',
                                'text' : 'Holz',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/02-02.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Materialmix',
                                'text' : 'Materialmix',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/02-03.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wie soll die Terrassenüberdachung konstruiert werden?',
                        'key' : 'wie-konstruiert',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Als Anbau',
                                'text' : 'Anbau',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/03-01.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Freistehend',
                                'text' : 'Freistehend',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/03-02.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Welche Dachform bevorzugen Sie?',
                        'key' : 'dachform',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Pultdach',
                                'text' : 'Pultdach',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/04-01.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Flachdach',
                                'text' : 'Flachdach',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/04-02.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Satteldach',
                                'text' : 'Satteldach',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/04-03.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Walmdach',
                                'text' : 'Walmdach',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/04-04.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wie soll die Terrassenüberdachung gedeckt werden?',
                        'key' : 'dachdeckung',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Glas',
                                'text' : 'Glas (empfohlen)',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/05-01.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Ziegeln',
                                'text' : 'Ziegeln',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/05-02.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Dachbegrünung',
                                'text' : 'Dachbegrünung',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/05-03.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Stegplatten',
                                'text' : 'Stegplatten',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/05-04.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Sind Seiten- oder Frontelemente erwünscht?',
                        'key' : 'element',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/06-01.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/06-02.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Mit welchen Kosten rechnen Sie?',
                        'key' : 'budget',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Bis 4.000 Euro',
                                'text' : 'Bis 4.000 €',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/07-01.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '4.000 - 12.000 Euro',
                                'text' : '4.000 - 12.000 €',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/07-02.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '12.000 - 20.000 Euro',
                                'text' : '12.000 - 20.000 €',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/07-03.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wann ist das Bauvorhaben geplant?',
                        'key' : 'bauvorhaben',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Kurzfristig (1 - 3 Monate)',
                                'text' : 'Kurzfristig (1 - 3 Monate)',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/08-01.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Mittelfristig (3 - 12 Monate)',
                                'text' : 'Mittelfristig (3 - 12 Monate)',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/08-02.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Langfristig (Länger als 12 Monate)',
                                'text' : 'Langfristig (> 12 Monate)',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/08-03.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/terrassendach/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                ]
            },
            {
                'component': 'ZipStep'
            },
            {
                'component': 'SearchStep',
                'subtitle': 'Bitte haben Sie einen Augenblick Geduld.'
            },
            {
                'component': 'DataStep',
                'title': 'Wer soll die Angebote erhalten?',
                'fields': [
                    'name',
                    'street',
                    'email',
                    'telefon'
                ],
                'btn_title' : 'Vergleichen & sparen',
                'btn_subtitle' : 'kostenlos & unverbindlich',
            },
            {
                'component': 'AfterSave',
                'cross_selling' : false,
                'cross_selling_options': {
                    'title': 'Sind Sie auch an einem der folgenden Produkte für Ihr Gewerbe interessiert?',
                    'ad_type': 'AdImageBox',
                    'number_of_ads': 3,
                    'ad_items': [
                        {
                            'text': 'Snack-/Getränkeautomat',
                            'image': new URL('./../assets/imgs/cross_selling/snackautomat.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Snackautomat',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Wasserspender',
                            'image': new URL('./../assets/imgs/cross_selling/wasserspender.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Wasserspender',
                            'text_position': 'bottom',
                        }
                    ]
                },
            },






        ],

    };

    var immobilienConfig = {
        'html_title': 'Verkaufe Deine Immobilie',
        'type': 'IMMOBILIE',
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.',
            '1) Unsere Anbieter konkurrieren mit 2 weiteren Anbietern aus unserem Netzwerk, somit profitieren Sie durch bessere Angebote, welche Sie im Einzelfall durch den Wettbewerb bei unseren Anbietern erzielen.',
        ],
        'logo' : new URL('./../assets/imgs/types/immobilie/general/logo.png?width=300&format=jpeg', import.meta.url),
        'logo_big' : true,
        'bg_image' : new URL('./../assets/imgs/types/immobilie/general/bg.jpeg?width=2000&format=jpeg', import.meta.url),
        'bg_blur' : '5px',
        'bg_pos': 'center bottom',
        'bg_isDark': true,

        'head_right' : [
            '<div class="lead-counter">Bereits <span>51.384</span><br/> Anfragen vermittelt</div>',
            '<div class="lead-counter">Mehr als <span>200</span><br/> Anbieter</div>'
        ],



        'steps': [

            {
                'component': 'Stepper',
                'title' : 'Immobilie richtig verkaufen!',
                'subtitle' : 'Wir ermitteln die optimale Verkaufsstrategie für Sie *',
                'steps' : [
                    {
                        'step_type': 'image',
                        'title' : 'Welche Immobilie möchten Sie verkaufen?',
                        'key' : 'type',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Grundstück',
                                'text' : 'Grundstück',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/type/type_grundstueck.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Haus',
                                'text' : 'Haus',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/type/type_haus.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Wohnung',
                                'text' : 'Wohnung',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/type/type_wohnung.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Gewerbe',
                                'text' : 'Gewerbe',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/type/type_gewerbe.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Grundstück'
                        },
                        'title' : 'Welche Fläche hat das Grundstück?',
                        'key' : 'grundstueck_flaeche',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'bis 300 m²',
                                'text' : 'bis 300 m²',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/grund.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '301 - 1000 m²',
                                'text' : '301 - 1000 m²',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/grund.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'über 1000 m²',
                                'text' : 'über 1000 m²',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/grund.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/grund.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Grundstück'
                        },
                        'title' : 'Ist das Grundstück erschlossen?',
                        'key' : 'grundstueck_erschlossen',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Erschlossen',
                                'text' : 'Erschlossen',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/developed-yes.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Teilerschlossen',
                                'text' : 'Teilerschlossen',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/developed-partly.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Unerschlossen',
                                'text' : 'Unerschlossen',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/developed-not.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Grundstück'
                        },
                        'title' : 'Wie sind die Bebauungsmöglichkeiten?',
                        'key' : 'grundstueck_bebauung',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Kurzfristig bebaubar',
                                'text' : 'Kurzfristig bebaubar',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/buildable.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Eingeschränkt bebaubar',
                                'text' : 'Eingeschränkt bebaubar',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/partly-buildable.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nicht bebaubar',
                                'text' : 'Nicht bebaubar',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/not-buildable.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Grundstück'
                        },
                        'title' : 'Wie ist der Grundstückszuschnitt?',
                        'key' : 'grundstueck_zuschnitt',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Eckgrundstück',
                                'text' : 'Eckgrundstück',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/ground-corner.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Rechteckiger Zuschnitt',
                                'text' : 'Rechteckiger Zuschnitt',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/ground-rect.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Sonstiges',
                                'text' : 'Sonstiges',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/ground-other.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Grundstück'
                        },
                        'title' : 'Wie ist der Grundstückszuschnitt?',
                        'key' : 'grundstueck_zuschnitt',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Eckgrundstück',
                                'text' : 'Eckgrundstück',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/ground-corner.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Rechteckiger Zuschnitt',
                                'text' : 'Rechteckiger Zuschnitt',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/ground-rect.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Sonstiges',
                                'text' : 'Sonstiges',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/ground-other.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Bitte wählen Sie die Art des Hauses aus',
                        'key' : 'haus_art',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Einfamilienhaus',
                                'text' : 'Einfamilienhaus',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/haus_art/haus_art_einfamilienhaus.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Zweifamilienhaus',
                                'text' : 'Zweifamilienhaus',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/haus_art/haus_art_zweifamilienhaus.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Reihenhaus',
                                'text' : 'Reihenhaus',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/haus_art/haus_art_reihenhaus.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Doppelhaushälfte',
                                'text' : 'Doppelhaushälfte',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/haus_art/haus_art_doppelhaushaelfte.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Mehrfamilienhaus',
                                'text' : 'Mehrfamilienhaus',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/haus_art/haus_art_mehrfamilienhaus.png?width=200&format=png', import.meta.url)
                            },
                        ],
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'haus_art',
                            'value' : 'Mehrfamilienhaus'
                        },
                        'title' : 'Wie viele Wohneinheiten gibt es?',
                        'key' : 'haus_mehrfamilienhaus_wohneinheiten',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 3,
                        'max': 25,
                        'value': 3,
                        'step': 1,
                        'append_value': 'Einheiten'
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Welche Fläche hat das Grundstück des Hauses?',
                        'key' : 'haus_flaeche_grund',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 200,
                        'max': 2500,
                        'value': 500,
                        'step': 100,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Wie ist die gesamte Wohnfläche des Hauses?',
                        'key' : 'haus_flaeche_wohn',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 50,
                        'max': 500,
                        'value': 100,
                        'step': 10,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Wie viele Etagen hat das Haus?',
                        'key' : 'haus_etagenanzahl',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : '1',
                                'text' : 'Eine',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/etage-1.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '1,5',
                                'text' : 'Eineinhalb',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/etage-1.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '2',
                                'text' : 'Zwei',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/etage-1.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '2+',
                                'text' : 'Mehr als 2',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/etage-1.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Wie viele Zimmer hat das Haus? (ohne Küche und Bad)',
                        'key' : 'haus_anzahl_zimmer',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 3,
                        'max': 10,
                        'value': 5,
                        'step': 1,
                        'append_value': ''
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Wann wurde das Haus gebaut?',
                        'key' : 'haus_baujahr',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 1930,
                        'max': 2020,
                        'value': 1980,
                        'step': 5,
                        'append_value': ''
                    },
                    /*
                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Welche Parkmöglichkeiten sind am Haus vorhanden?',
                        'key' : 'haus_parken',
                        'multiple' : true,
                        'choices' : [
                            {
                                'value' : 'Garage',
                                'text' : 'Garage',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-garage.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Carport',
                                'text' : 'Carport',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-carport.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Stellplatz im Freien',
                                'text' : 'Stellplatz im Freien',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-aussen.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Keine Parkmöglichkeit',
                                'text' : 'Keine Parkmöglichkeit',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-keine.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                     */



                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'title' : 'Bitte wählen Sie die Art der Wohnung aus',
                        'key' : 'wohnung_art',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Erdgeschosswohnung',
                                'text' : 'Erdgeschosswohnung',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/wohnung_art/wohnung_art_erdgeschosswohnung.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Etagenwohnung',
                                'text' : 'Etagenwohnung',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/wohnung_art/wohnung_art_etagenwohnung.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Dachgeschosswohnung',
                                'text' : 'Dachgeschosswohnung',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/wohnung_art/wohnung_art_dachgeschosswohnung.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Maisonette',
                                'text' : 'Maisonette',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/wohnung_art/wohnung_art_maisonette.png?width=200&format=png', import.meta.url)
                            },
                        ],
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'title' : 'Wie ist die Wohnfläche der Wohnung?',
                        'key' : 'wohnung_flaeche',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/wohnung.png?width=200&format=png', import.meta.url),
                        'min': 20,
                        'max': 200,
                        'value': 100,
                        'step': 10,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'title' : 'Wie viele Zimmer hat die Wohnung? (ohne Küche und Bad)',
                        'key' : 'wohnung_zimmer',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/wohnung.png?width=200&format=png', import.meta.url),
                        'min': 1,
                        'max': 6,
                        'value': 4,
                        'step': 1,
                        'append_value': ''
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'title' : 'Wann wurde das Wohnhaus gebaut?',
                        'key' : 'wohnung_baujahr',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/wohnung.png?width=200&format=png', import.meta.url),
                        'min': 1930,
                        'max': 2020,
                        'value': 1980,
                        'step': 5,
                        'append_value': ''
                    },
                    /*
                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'title' : 'Welche Parkmöglichkeiten sind an der Wohnung vorhanden?',
                        'key' : 'wohnung_parken',
                        'multiple' : false, // TODO Multiple geht hier nicht, wieso auch immer
                        'choices' : [
                            {
                                'value' : 'Garage',
                                'text' : 'Garage',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-garage.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Carport',
                                'text' : 'Carport',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-carport.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Stellplatz im Freien',
                                'text' : 'Stellplatz im Freien',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-aussen.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Keine Parkmöglichkeit',
                                'text' : 'Keine Parkmöglichkeit',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-keine.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },


                     */





                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'title' : 'Um welche Gebäudeart handelt es sich?',
                        'key' : 'gewerbe_art',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Büro- oder Lagergebäude',
                                'text' : 'Büro- oder Lagergebäude',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-garage.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Wohn- und Geschäftsgebäude',
                                'text' : 'Wohn- und Geschäftsgebäude',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/wohnung.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Industrie- oder Gewerbegebäude',
                                'text' : 'Industrie- oder Gewerbegebäude',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/gewerbe.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Sonstiges',
                                'text' : 'Sonstiges',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'title' : 'Welche Fläche hat das Grundstück des Gewerbes?',
                        'key' : 'gewerbe_flaeche_grund',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/gewerbe.png?width=200&format=png', import.meta.url),
                        'min': 200,
                        'max': 2500,
                        'value': 500,
                        'step': 100,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'title' : 'Wie ist die Nutzfläche des Gebäudes?',
                        'key' : 'gewerbe_flaeche_nutz',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/gewerbe.png?width=200&format=png', import.meta.url),
                        'min': 50,
                        'max': 500,
                        'value': 100,
                        'step': 10,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'title' : 'Wann wurde das Gewerbegebäude gebaut?',
                        'key' : 'gewerbe_baujahr',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/gewerbe.png?width=200&format=png', import.meta.url),
                        'min': 1930,
                        'max': 2020,
                        'value': 1980,
                        'step': 5,
                        'append_value': ''
                    },
                    /*
                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'title' : 'Welche Parkmöglichkeiten sind am Gewerbe vorhanden?',
                        'key' : 'gewerbe_parken',
                        'multiple' : true,
                        'choices' : [
                            {
                                'value' : 'Garage',
                                'text' : 'Garage',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-garage.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Carport',
                                'text' : 'Carport',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-carport.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Stellplatz im Freien',
                                'text' : 'Stellplatz im Freien',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-aussen.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Keine Parkmöglichkeit',
                                'text' : 'Keine Parkmöglichkeit',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-keine.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                     */




                    /*
                    {
                        'step_type': 'image',
                        'title' : 'Kennen Sie den aktuellen Wert Ihrer Immobilie?',
                        'key' : 'immobilienwert_bekannt',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-ja.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-nein.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Unsicher',
                                'text' : 'Unsicher',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/fragezeichen-orange.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                     */
                    /*
                    {
                        'step_type': 'image',
                        'title' : 'Haben Sie Erfahrung beim Immobilienverkauf?',
                        'key' : 'erfahrung',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-ja.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-nein.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                     */
                    {
                        'step_type': 'image',
                        'title' : 'Für welches Thema interessieren Sie sich?',
                        'key' : 'interesse',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Verkauf',
                                'text' : 'Verkauf',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-v.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Kauf',
                                'text' : 'Kauf',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-k.png?width=200&format=png', import.meta.url)
                            }/*,
                            {

                                'value' : 'Wertermittlung',
                                'text' : 'Reine Wertermittlung',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-w.png?width=200&format=png', import.meta.url)
                            }*/
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wann wollen Sie verkaufen?',
                        'key' : 'verkaufszeitpunkt',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Schnellstmöglich',
                                'text' : 'Schnellstmöglich',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/verkaufszeitpunkt/verkaufszeitpunkt_schnellstmoeglich.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Innerhalb 6 Monaten',
                                'text' : 'Innerhalb 6 Monaten',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/verkaufszeitpunkt/verkaufszeitpunkt_6_monate.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Innerhalb 1 Jahr',
                                'text' : 'Innerhalb 1 Jahr',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/verkaufszeitpunkt/verkaufszeitpunkt_1_jahr.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Momentan nicht',
                                'text' : 'Momentan nicht',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/new/verkaufszeitpunkt/verkaufszeitpunkt_momentan_nicht.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    }
                ]
            },
            {
                'component': 'ZipStep',
                'title': 'Gleich geschafft, bitte geben Sie Ihre Addresse an:'
            },

            {
                'component': 'SearchStep',
                'subtitle': 'Unser System errechnet für sie eine optimale Immobilienbewertung.'
            },
            {
                'component': 'DataStep',
                'title': 'Erhalten Sie jetzt Ihre individuelle Verkaufsempfehlung',
                'subtitle': 'Wer soll die Angebote erhalten?',
                'fields': [
                    'name',
                    'email',
                    'telefon'
                ],
                'btn_title' : 'Verkaufsempfehlung erhalten',
                'btn_subtitle' : 'kostenlos & unverbindlich',
                'head_notice' : 'Ihre kostenlose Verkaufsempfehlung wurde erstellt!',
            },

            {
                'component': 'AfterSave',
                'text': 'Damit Sie eine passende Immobilienbewertung von unseren ausgesuchten Maklern erhalten können, müssen wir Ihre Kontaktdaten auf Richtigkeit überprüfen. Dazu wird Sie in Kürze ein Kundenberater telefonisch kontaktieren.',
                'cross_selling' : true,
                'cross_selling_options': {
                    'title': 'Sind Sie an einer Wertsteigerung Ihrer Immobilie durch eine der folgenden Produkte interessiert?',
                    'ad_type': 'AdImageBox',
                    'number_of_ads': 3,
                    'ad_items': [
                        {
                            'text': 'Solaranlage',
                            'image': new URL('./../assets/imgs/cross_selling/solaranlage.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Solaranlage',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Terrassendach',
                            'image': new URL('./../assets/imgs/cross_selling/terrassendach.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Terrassendach',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Wintergarten',
                            'image': new URL('./../assets/imgs/cross_selling/wintergarten.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Wintergarten',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Neue Fenster',
                            'image': new URL('./../assets/imgs/cross_selling/fenster.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Fenster',
                            'text_position': 'bottom',
                        },
                    ]
                },
            },






        ],

    };
    var immobilienConfigMap = {
        'html_title': 'Verkaufe Deine Immobilie',
        'type': 'IMMOBILIE',
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.',
            '1) Unsere Anbieter konkurrieren mit 2 weiteren Anbietern aus unserem Netzwerk, somit profitieren Sie durch bessere Angebote, welche Sie im Einzelfall durch den Wettbewerb bei unseren Anbietern erzielen.',
        ],
        'logo' : new URL('./../assets/imgs/types/immobilie/general/logo.png?width=300&format=jpeg', import.meta.url),
        'logo_big' : true,
        'bg_image' : new URL('./../assets/imgs/types/immobilie/general/bg-map-blue.jpg?width=2000&format=jpeg', import.meta.url),
        'bg_blur' : '2px',
        'bg_pos': 'center bottom',
        'bg_isDark': false,

        'head_right' : [
            '<div class="lead-counter">Bereits <span>51.384</span><br/> Anfragen vermittelt</div>',
            '<div class="lead-counter">Mehr als <span>200</span><br/> Anbieter</div>'
        ],







        'steps': [


            {
                'component': 'Stepper',
                'title' : 'Immobilie richtig verkaufen!',
                'subtitle' : 'Wir ermitteln die optimalen Verkaufsstrategie für Sie *',
                'steps' : [


                    {
                        'step_type': 'image',
                        'title' : 'Welche Immobilie möchten Sie verkaufen?',
                        'key' : 'type',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Grundstück',
                                'text' : 'Grundstück',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/grund.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Haus',
                                'text' : 'Haus',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Wohnung',
                                'text' : 'Wohnung',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/wohnung.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Gewerbe',
                                'text' : 'Gewerbe',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/gewerbe.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Grundstück'
                        },
                        'title' : 'Welche Fläche hat das Grundstück?',
                        'key' : 'grundstueck_flaeche',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'bis 300 m²',
                                'text' : 'bis 300 m²',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/grund.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '301 - 1000 m²',
                                'text' : '301 - 1000 m²',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/grund.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'über 1000 m²',
                                'text' : 'über 1000 m²',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/grund.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/grund.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Grundstück'
                        },
                        'title' : 'Ist das Grundstück erschlossen?',
                        'key' : 'grundstueck_erschlossen',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Erschlossen',
                                'text' : 'Erschlossen',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/developed-yes.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Teilerschlossen',
                                'text' : 'Teilerschlossen',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/developed-partly.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Unerschlossen',
                                'text' : 'Unerschlossen',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/developed-not.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Grundstück'
                        },
                        'title' : 'Wie sind die Bebauungsmöglichkeiten?',
                        'key' : 'grundstueck_bebauung',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Kurzfristig bebaubar',
                                'text' : 'Kurzfristig bebaubar',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/buildable.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Eingeschränkt bebaubar',
                                'text' : 'Eingeschränkt bebaubar',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/partly-buildable.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nicht bebaubar',
                                'text' : 'Nicht bebaubar',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/not-buildable.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Grundstück'
                        },
                        'title' : 'Wie ist der Grundstückszuschnitt?',
                        'key' : 'grundstueck_zuschnitt',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Eckgrundstück',
                                'text' : 'Eckgrundstück',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/ground-corner.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Rechteckiger Zuschnitt',
                                'text' : 'Rechteckiger Zuschnitt',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/ground-rect.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Sonstiges',
                                'text' : 'Sonstiges',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/ground-other.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },




                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Welche Fläche hat das Grundstück des Hauses?',
                        'key' : 'haus_flaeche_grund',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 200,
                        'max': 2500,
                        'value': 500,
                        'step': 100,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Wie ist die gesamte Wohnfläche des Hauses?',
                        'key' : 'haus_flaeche_wohn',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 50,
                        'max': 500,
                        'value': 100,
                        'step': 10,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Wie viele Etagen hat das Haus?',
                        'key' : 'haus_etagenanzahl',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : '1',
                                'text' : 'Eine',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/etage-1.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '1,5',
                                'text' : 'Eineinhalb',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/etage-1.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '2',
                                'text' : 'Zwei',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/etage-1.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '2+',
                                'text' : 'Mehr als 2',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/etage-1.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Wie viele Zimmer hat das Haus? (ohne Küche und Bad)',
                        'key' : 'haus_anzahl_zimmer',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 3,
                        'max': 10,
                        'value': 5,
                        'step': 1,
                        'append_value': ''
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Wann wurde das Haus gebaut?',
                        'key' : 'haus_baujahr',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 1930,
                        'max': 2020,
                        'value': 1980,
                        'step': 5,
                        'append_value': ''
                    },
                    /*
                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'angebot',
                            'value' : 'Haus'
                        },
                        'title' : 'Welche Parkmöglichkeiten sind am Haus vorhanden?',
                        'key' : 'haus_parken',
                        'multiple' : true,
                        'choices' : [
                            {
                                'value' : 'Garage',
                                'text' : 'Garage',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-garage.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Carport',
                                'text' : 'Carport',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-carport.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Stellplatz im Freien',
                                'text' : 'Stellplatz im Freien',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-aussen.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Keine Parkmöglichkeit',
                                'text' : 'Keine Parkmöglichkeit',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-keine.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },


                     */




                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'title' : 'Wie ist die Wohnfläche der Wohnung?',
                        'key' : 'wohnung_flaeche',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/wohnung.png?width=200&format=png', import.meta.url),
                        'min': 20,
                        'max': 200,
                        'value': 100,
                        'step': 10,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'title' : 'Wie viele Zimmer hat die Wohnung? (ohne Küche und Bad)',
                        'key' : 'wohnung_zimmer',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/wohnung.png?width=200&format=png', import.meta.url),
                        'min': 1,
                        'max': 6,
                        'value': 4,
                        'step': 1,
                        'append_value': ''
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'title' : 'Wann wurde das Wohnhaus gebaut?',
                        'key' : 'wohnung_zimmer',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/wohnung.png?width=200&format=png', import.meta.url),
                        'min': 1930,
                        'max': 2020,
                        'value': 1980,
                        'step': 5,
                        'append_value': ''
                    },
                    /*
                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'angebot',
                            'value' : 'Wohnung'
                        },
                        'title' : 'Welche Parkmöglichkeiten sind an der Wohnung vorhanden?',
                        'key' : 'wohnung_parken',
                        'multiple' : false, // TODO Multiple geht hier nicht, wieso auch immer
                        'choices' : [
                            {
                                'value' : 'Garage',
                                'text' : 'Garage',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-garage.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Carport',
                                'text' : 'Carport',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-carport.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Stellplatz im Freien',
                                'text' : 'Stellplatz im Freien',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-aussen.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Keine Parkmöglichkeit',
                                'text' : 'Keine Parkmöglichkeit',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-keine.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                     */






                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'title' : 'Um welche Gebäudeart handelt es sich?',
                        'key' : 'gewerbe_art',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Büro- oder Lagergebäude',
                                'text' : 'Büro- oder Lagergebäude',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-garage.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Wohn- und Geschäftsgebäude',
                                'text' : 'Wohn- und Geschäftsgebäude',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/wohnung.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Industrie- oder Gewerbegebäude',
                                'text' : 'Industrie- oder Gewerbegebäude',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/gewerbe.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Sonstiges',
                                'text' : 'Sonstiges',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'title' : 'Welche Fläche hat das Grundstück des Gewerbes?',
                        'key' : 'gewerbe_flaeche_grund',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/gewerbe.png?width=200&format=png', import.meta.url),
                        'min': 200,
                        'max': 2500,
                        'value': 500,
                        'step': 100,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'title' : 'Wie ist die Nutzfläche des Gebäudes?',
                        'key' : 'gewerbe_flaeche_nutz',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/gewerbe.png?width=200&format=png', import.meta.url),
                        'min': 50,
                        'max': 500,
                        'value': 100,
                        'step': 10,
                        'append_value': 'm²'
                    },{
                        'step_type': 'slider',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'title' : 'Wann wurde das Gewerbegebäude gebaut?',
                        'key' : 'gewerbe_baujahr',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/gewerbe.png?width=200&format=png', import.meta.url),
                        'min': 1930,
                        'max': 2020,
                        'value': 1980,
                        'step': 5,
                        'append_value': ''
                    },
                    /*
                    {
                        'step_type': 'image',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'title' : 'Welche Parkmöglichkeiten sind am Gewerbe vorhanden?',
                        'key' : 'gewerbe_parken',
                        'multiple' : true,
                        'choices' : [
                            {
                                'value' : 'Garage',
                                'text' : 'Garage',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-garage.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Carport',
                                'text' : 'Carport',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-carport.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Stellplatz im Freien',
                                'text' : 'Stellplatz im Freien',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-aussen.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Keine Parkmöglichkeit',
                                'text' : 'Keine Parkmöglichkeit',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/park-keine.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                    */


                    /*
                    {
                        'step_type': 'image',
                        'title' : 'Kennen Sie den aktuellen Wert Ihrer Immobilie?',
                        'key' : 'immobilienwert_bekannt',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-ja.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-nein.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Unsicher',
                                'text' : 'Unsicher',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/fragezeichen-orange.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                     */
                    /*
                    {
                        'step_type': 'image',
                        'title' : 'Haben Sie Erfahrung beim Immobilienverkauf?',
                        'key' : 'erfahrung',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-ja.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-nein.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },

                     */
                    {
                        'step_type': 'image',
                        'title' : 'Für welches Thema interessieren Sie sich?',
                        'key' : 'interesse',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Verkauf',
                                'text' : 'Verkauf',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-v.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Kauf',
                                'text' : 'Kauf',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-k.png?width=200&format=png', import.meta.url)
                            } /*,
                            {
                                'value' : 'Wertermittlung',
                                'text' : 'Reine Wertermittlung',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-w.png?width=200&format=png', import.meta.url)
                            }*/
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Teilverkauf erwünscht?',
                        'condition': {
                            'key': 'interesse',
                            'value': 'Verkauf',
                        },
                        'key' : 'teilverkauf',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-ja-blau.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-nein-blau.png?width=200&format=png', import.meta.url)
                            },
                            {

                                'value' : 'Telefonische Beratung',
                                'text' : 'Telefonische Beratung',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/icon-phone-blau.png?width=200&format=png', import.meta.url)
                            },
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wann wollen Sie verkaufen?',
                        'key' : 'verkaufszeitpunkt',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Schnellstmöglich',
                                'text' : 'Schnellstmöglich',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/time-asap.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Innerhalb 6 Monaten',
                                'text' : 'Innerhalb 6 Monaten',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/time-1-6-monate.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Innerhalb 2 Jahre',
                                'text' : 'Innerhalb 2 Jahre',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/time-kleiner-2-jahre.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nach 2 Jahren',
                                'text' : 'Nach 2 Jahren',
                                'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/time-groesser-2-jahre.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    }
                ]
            },
            {
                'component': 'ZipStep'
            },

            {
                'component': 'SearchStep',
                'subtitle': 'Unser System errechnet für sie eine optimale Immobilienbewertung.'
            },
            {
                'component': 'DataStep',
                'title': 'Erhalten Sie jetzt Ihre individuelle Verkaufsempfehlung',
                'subtitle': 'Wer soll die Angebote erhalten?',
                'fields': [
                    'name',
                    'email',
                    'telefon'
                ],
                'btn_title' : 'Verkaufsempfehlung erhalten',
                'btn_subtitle' : 'kostenlos & unverbindlich',
                'head_notice' : 'Ihre kostenlose Verkaufsempfehlung wurde erstellt!',
            },
            {
                'component': 'AfterSave',
                'text': 'Damit Sie eine passende Immobilienbewertung von unseren ausgesuchten Maklern erhalten können, müssen wir Ihre Kontaktdaten auf Richtigkeit überprüfen. Dazu wird Sie in Kürze ein Kundenberater telefonisch kontaktieren.',
                'cross_selling' : true,
                'cross_selling_options': {
                    'title': 'Sind Sie an einer Wertsteigerung Ihrer Immobilie durch eine der folgenden Produkte interessiert?',
                    'ad_type': 'AdImageBox',
                    'number_of_ads': 3,
                    'ad_items': [
                        {
                            'text': 'Solaranlage',
                            'image': new URL('./../assets/imgs/cross_selling/solaranlage.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Solaranlage',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Terrassendach',
                            'image': new URL('./../assets/imgs/cross_selling/terrassendach.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Terrassendach',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Wintergarten',
                            'image': new URL('./../assets/imgs/cross_selling/wintergarten.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Wintergarten',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Neue Fenster',
                            'image': new URL('./../assets/imgs/cross_selling/fenster.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Fenster',
                            'text_position': 'bottom',
                        },
                    ]
                },
            },







        ],

    };


    var baufinanzierungConfig = {
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.',
            //'**Der Vergleich zwischen leitungsgebundenen Wasserspender und Wasserflasche basiert auf folgender Berechnung: Wasserverbrauch für 18 Personen, 1,5 Liter pro Tag, 20 Arbeitstage pro Monat, was 540 Liter pro Monat ergibt; Die Betriebskosten des leitungsgebundenen Wasserspenders i.H.v. Leitungswasser und Strom 0,02 Euro/Liter; Betriebskosten für die Bereitstellung von Wasserflaschen i. H. v. 0,52 Euro pro Liter Wasser (der hypothetische Preis pro 12-Liter-Kiste beträgt 6 Euro, Mengenrabatt i. H. v. 15%, Lagerung berücksichtigt den Gewerbe-Quadratmeterpreis von Berlin i. H. v. 26 Euro, keine Versandkosten; Wasserspender Miete/Abschreibung (über 60 Monate) i. H. v. 100 Euro pro Monat, inklusive Wartung und Filterwechsel.'
        ],
        'logo' : new URL('./../assets/imgs/types/baufinanzierung/logo.png?width=300&format=png', import.meta.url),
        'bg_image' : new URL('./../assets/imgs/types/baufinanzierung/bg.png?width=2000&format=jpeg', import.meta.url),

        'steps': [
            {
                'component': 'Stepper',
                'title' : 'Einfach Finanzieren',
                'subtitle' : 'Kostenlos & unverbindlich Finanzierungsangebote einholen & sparen*',
                //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
                'steps' : [

                    {
                        'step_type': 'text',
                        'title' : 'Was möchten sie finanzieren?',
                        'key' : 'typ',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Neubau einer Immobilie',
                                'value' : 'Neubau'
                            },
                            {
                                'text' : 'Kauf einer Immobilie',
                                'value' : 'Kauf'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie möchten Sie die Immobilie nutzen?',
                        'key' : 'nutzung',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Selbst bewohnen',
                                'text' : 'Selbst bewohnen'
                            },
                            {
                                'value' : 'Teilweise vermieten',
                                'text' : 'Teilweise vermieten'
                            },
                            {
                                'value' : 'Vermieten',
                                'text' : 'Vermieten'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie ist der aktuelle Stand der Immobiliensuche?',
                        'key' : 'stand',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Noch nicht in Planung',
                                'text' : 'Noch nicht in Planung'
                            },
                            {
                                'value' : 'Grundstück gefunden',
                                'text' : 'Grundstück gefunden'
                            },
                            {
                                'value' : 'Grundstück vorhanden',
                                'text' : 'Grundstück vorhanden'
                            },
                            {
                                'value' : 'Notartermin geplant',
                                'text' : 'Notartermin geplant'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wann möchten Sie finanzieren?',
                        'key' : 'zeitraum',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'In weniger als 3 Monaten',
                                'value' : '< 3 Monate'
                            },
                            {
                                'text' : 'In 3-6 Monaten',
                                'value' : '3-6 Monate'
                            },
                            {
                                'text' : 'In über 6 Monaten',
                                'value' : '> 6 Monate'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie hoch ist der gewünschte Finanzierungsbetrag?',
                        'key' : 'betrag',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Noch machen',
                                'text' : '< 3 Monate'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie hoch sind die geplanten Nebenkosten?',
                        'subtitle' : 'Grunderwerbssteuer, Notar, Makler - in der Regel ca. 10%',
                        'key' : 'nebenkosten',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'subtitle',
                                'text' : '< 3 Monate'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie viel Eigenkapital bringen Sie ein?',
                        'key' : 'eigenkapital',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'range',
                                'text' : '< 3 Monate'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'In welchem Beschäftigungsverhältnis stehen Sie?',
                        'key' : 'beschäftigung',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Angestellt',
                                'text' : 'Angestellt'
                            },
                            {
                                'value' : 'Selbstständig/Freiberuflich',
                                'text' : 'Selbstständig'
                            },
                            {
                                'value' : 'Arbeitssuchend',
                                'text' : 'Arbeitssuchend'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie hoch ist Ihr monatliches Haushaltsnettoeinkommen?',
                        'key' : 'einkommen',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : '1000-1500',
                                'text' : '1000-1500'
                            },
                            {
                                'value' : '1501-2000',
                                'text' : '1501-2000'
                            },
                            {
                                'value' : '2001-2500',
                                'text' : '2001-2500'
                            },
                            {
                                'value' : '2501-3000',
                                'text' : '2501-3000'
                            },
                            {
                                'value' : '3001-3500',
                                'text' : '3001-3500'
                            },
                            {
                                'value' : '3501-4000',
                                'text' : '3501-4000'
                            },
                            {
                                'value' : '>4000',
                                'text' : 'Über 4000'
                            },
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie lautet Ihr Geburtsdatum?',
                        'subtitle' : 'Relevant für die Höhe der Finanzierung',
                        'key' : 'geburtsdatum',
                        'multiple' : false,
                        'choices' : [

                        ],
                    },

                ]
            },
            {
                'component': 'ZipStep'
            },
            {
                'component': 'SearchStep'
            },
            {
                'component': 'DataStep'
            },






        ],

    };

    const immobilienConfigText = {
        'baseColor': '#005b36',
        'baseColorLight': '#007f4e',
        'baseColorText': '#ffffff',
        'html_title': 'Eigenheim',
        'type': 'IMMOBILIE',
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.',
        ],
        'logo' : new URL('./../assets/imgs/types/immobilie/general/logo.png?width=300&format=jpeg', import.meta.url),
        'logo_big' : true,
        'bg_image' : new URL('./../assets/imgs/types/immobilie/general/bg.jpeg?width=2000&format=jpeg', import.meta.url),
        'bg_blur' : '5px',
        'bg_overlay_color': '#ffffff',
        'bg_overlay_opacity': 0.5,
        'bg_pos': 'center bottom',
        'bg_isDark': true,
        'steps': [
            {
                'component': 'Stepper',
                'title' : '100% kostenlose Immobilienbewertung',
                'subtitle' : '',
                //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
                'steps' : [
                    {
                        'step_type': 'text',
                        'title' : 'Welche Immobilie möchten Sie bewerten lassen?',
                        'key' : 'type',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Haus',
                                'value' : 'Haus'
                            },
                            {
                                'text' : 'Wohnung',
                                'value' : 'Wohnung'
                            },
                            {
                                'text' : 'Grundstück',
                                'value' : 'Grundstück'
                            },
                            {
                                'text' : 'Gewerbe',
                                'value' : 'Gewerbe'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Um was für eine Art Haus handelt es sich?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'key' : 'haus_art',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Einfamilienhaus',
                                'value' : 'Einfamilienhaus'
                            },
                            {
                                'text' : 'Reihenhaus',
                                'value' : 'Reihenhaus'
                            },
                            {
                                'text' : 'Doppelhaushälfte',
                                'value' : 'Doppelhaushälfte'
                            },
                            {
                                'text' : 'Mehrfamilienhaus',
                                'value' : 'Mehrfamilienhaus'
                            },
                            {
                                'text' : 'Wohn-/Geschäftshaus',
                                'value' : 'Wohn-/Geschäftshaus'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Um was für eine Art Wohnung handelt es sich?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'key' : 'wohnung_art',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Erdgeschosswohnung',
                                'value' : 'Erdgeschosswohnung'
                            },
                            {
                                'text' : 'Etagenwohnung',
                                'value' : 'Etagenwohnung'
                            },
                            {
                                'text' : 'Dachgeschoss',
                                'value' : 'Dachgeschoss'
                            },
                            {
                                'text' : 'Andere',
                                'value' : 'Andere'
                            },

                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Um was für einen Grundstückstyp handelt es sich?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Grundstück'
                        },
                        'key' : 'grundstueck_art',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Bauland',
                                'value' : 'Bauland'
                            },
                            {
                                'text' : 'Ackerland',
                                'value' : 'Ackerland'
                            },
                            {
                                'text' : 'Wald/Grünland',
                                'value' : 'Wald/Grünland'
                            },
                            {
                                'text' : 'Sonstiges',
                                'value' : 'Sonstiges'
                            },

                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Um was für eine Art Gebäude handelt es sich?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'key' : 'gewerbe_art',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Büro/Praxis',
                                'value' : 'Büro/Praxis'
                            },
                            {
                                'text' : 'Halle/Produktion',
                                'value' : 'Halle/Produktion'
                            },
                            {
                                'text' : 'Gastronomie/Hotel',
                                'value' : 'Gastronomie/Hotel'
                            },
                            {
                                'text' : 'Einzelhandel',
                                'value' : 'Einzelhandel'
                            },
                            {
                                'text' : 'Sonstiges',
                                'value' : 'Sonstiges'
                            },

                        ],
                    },
                    {
                        'step_type': 'slider_simple',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Wie groß ist die Wohnfläche?',
                        'key' : 'haus_flaeche_wohn',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 30,
                        'max': 700,
                        'value': 100,
                        'step': 1,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider_simple',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'title' : 'Wie groß ist die Wohnfläche?',
                        'key' : 'wohnung_flaeche',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 30,
                        'max': 700,
                        'value': 100,
                        'step': 1,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider_simple',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Grundstück'
                        },
                        'title' : 'Wie groß ist die Grundstücksfläche?',
                        'key' : 'grundstueck_flaeche',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 0,
                        'max': 20000,
                        'value': 200,
                        'step': 100,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider_simple',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'title' : 'Wie groß ist die Gewerbefläche?',
                        'key' : 'gewerbe_flaeche_nutz',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 0,
                        'max': 10000,
                        'value': 200,
                        'step': 100,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Ist das Grundstück erschlossen?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Grundstück'
                        },
                        'key' : 'grundstueck_erschlossen',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Erschlossen',
                                'text' : 'Erschlossen'
                            },
                            {
                                'value' : 'Teilerschlossen',
                                'text' : 'Teilerschlossen'
                            },
                            {
                                'value' : 'Unerschlossen',
                                'text' : 'Unerschlossen'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie ist der Grundstückszuschnitt?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Grundstück'
                        },
                        'key' : 'grundstueck_zuschnitt',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Rechteckiger Zuschnitt',
                                'text' : 'Rechteckiger Zuschnitt'
                            },
                            {
                                'value' : 'Eckgrundstück',
                                'text' : 'Eckgrundstück'
                            },
                            {
                                'value' : 'Sonstiges',
                                'text' : 'Sonstiges'
                            }
                        ],
                    },
                    {
                        'step_type': 'slider_simple',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Wie groß ist die Grundstücksfläche?',
                        'key' : 'haus_flaeche_grund',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 0,
                        'max': 10000,
                        'value': 200,
                        'step': 1,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider_simple',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'title' : 'Wie groß ist die Grundstücksfläche?',
                        'key' : 'gewerbe_flaeche_grund',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 0,
                        'max': 10000,
                        'value': 200,
                        'step': 1,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider_simple',
                        'title' : 'Wie viele Zimmer hat die Immobilie? (ohne Küche/Bad)',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'key' : 'haus_anzahl_zimmer',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 0,
                        'max': 15,
                        'value': 4,
                        'step': 1,
                        'append_value': ''
                    },
                    {
                        'step_type': 'slider_simple',
                        'title' : 'Wie viele Zimmer hat die Immobilie? (ohne Küche/Bad)',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'key' : 'wohnung_zimmer',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 0,
                        'max': 15,
                        'value': 4,
                        'step': 1,
                        'append_value': ''
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie viele Bäder hat die Immobilie?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'key' : 'haus_anzahl_baeder',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : '1',
                                'text' : '1 Bad'
                            },
                            {
                                'value' : '2',
                                'text' : '2 Bäder'
                            },
                            {
                                'value' : '3',
                                'text' : '3+ Bäder'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie viele Bäder hat die Wohnung?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'key' : 'wohnung_anzahl_baeder',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : '1',
                                'text' : '1 Bad'
                            },
                            {
                                'value' : '2',
                                'text' : '2 Bäder'
                            },
                            {
                                'value' : '3',
                                'text' : '3+ Bäder'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Sind Sie der Eigentümer der Immobilie?',
                        'key' : 'ist_eigentuemer',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja'
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein'
                            },
                            {
                                'value' : 'Teileigentümer',
                                'text' : 'Teileigentümer'
                            },
                            {
                                'value' : 'Angehöriger',
                                'text' : 'Angehöriger'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Sind Sie der Eigentümer der Wohnung?',
                        'key' : 'ist_eigentuemer',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja'
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein'
                            },
                            {
                                'value' : 'Teileigentümer',
                                'text' : 'Teileigentümer'
                            },
                            {
                                'value' : 'Angehöriger',
                                'text' : 'Angehöriger'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Sind Sie der Eigentümer des Grundstücks?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Grundstück'
                        },
                        'key' : 'ist_eigentuemer',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja'
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein'
                            },
                            {
                                'value' : 'Teileigentümer',
                                'text' : 'Teileigentümer'
                            },
                            {
                                'value' : 'Angehöriger',
                                'text' : 'Angehöriger'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Sind Sie der Eigentümer der Immobilie?',
                        'key' : 'ist_eigentuemer',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja'
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein'
                            },
                            {
                                'value' : 'Teileigentümer',
                                'text' : 'Teileigentümer'
                            },
                            {
                                'value' : 'Angehöriger',
                                'text' : 'Angehöriger'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie ist der Zustand der Immobilie?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'key' : 'zustand',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Gehoben',
                                'value' : 'Gehoben'
                            },
                            {
                                'text' : 'Normal',
                                'value' : 'Normal'
                            },
                            {
                                'text' : 'Einfach',
                                'value' : 'Einfach'
                            },
                            {
                                'text' : 'Renovierungsbedürftig',
                                'value' : 'Renovierungsbedürftig'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie ist der Zustand der Immobilie?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'key' : 'zustand',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Gehoben',
                                'value' : 'Gehoben'
                            },
                            {
                                'text' : 'Normal',
                                'value' : 'Normal'
                            },
                            {
                                'text' : 'Einfach',
                                'value' : 'Einfach'
                            },
                            {
                                'text' : 'Renovierungsbedürftig',
                                'value' : 'Renovierungsbedürftig'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie ist der Zustand der Immobilie?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'key' : 'zustand',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Gehoben',
                                'value' : 'Gehoben'
                            },
                            {
                                'text' : 'Normal',
                                'value' : 'Normal'
                            },
                            {
                                'text' : 'Einfach',
                                'value' : 'Einfach'
                            },
                            {
                                'text' : 'Renovierungsbedürftig',
                                'value' : 'Renovierungsbedürftig'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie wird die Immobilie aktuell genutzt?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'key' : 'nutzung',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Selbst bewohnt',
                                'value' : 'Selbst bewohnt'
                            },
                            {
                                'text' : 'Vermietet',
                                'value' : 'Vermietet'
                            },
                            {
                                'text' : 'Teilweise vermietet',
                                'value' : 'Teilweise vermietet'
                            },
                            {
                                'text' : 'Frei',
                                'value' : 'Frei'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Ist die Wohnung vermietet?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'key' : 'wohnung_vermietet',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Selbst bewohnt',
                                'value' : 'Selbst bewohnt'
                            },
                            {
                                'text' : 'Vermietet',
                                'value' : 'Vermietet'
                            },
                            {
                                'text' : 'Leerstehend',
                                'value' : 'Leerstehend'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Ist die Gewerbeimmobilie vermietet?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'key' : 'gewerbe_vermietet',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Selbst genutzt',
                                'value' : 'Selbst genutzt'
                            },
                            {
                                'text' : 'Vermietet',
                                'value' : 'Vermietet'
                            },
                            {
                                'text' : 'Leerstehend',
                                'value' : 'Leerstehend'
                            }
                        ],
                    },
                    {
                        'step_type': 'slider_simple',
                        'title' : 'Wann wurde das Haus ungefähr erbaut?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'key' : 'haus_baujahr',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 1900,
                        'max': 2022,
                        'value': 1970,
                        'step': 1,
                        'append_value': ''
                    },
                    {
                        'step_type': 'slider_simple',
                        'title' : 'Aus welchem Baujahr stammt das Haus?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'key' : 'wohnung_baujahr',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 1900,
                        'max': 2022,
                        'value': 1970,
                        'step': 1,
                        'append_value': ''
                    },
                    {
                        'step_type': 'slider_simple',
                        'title' : 'Aus welchem Baujahr stammt die Immobilie?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Gewerbe'
                        },
                        'key' : 'gewerbe_baujahr',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 1900,
                        'max': 2022,
                        'value': 1970,
                        'step': 1,
                        'append_value': ''
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wann können Sie sich einen Verkauf vorstellen?',
                        'key' : 'verkaufszeitpunkt',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Schnellstmöglich',
                                'text' : 'Schnellstmöglich'
                            },
                            {
                                'value' : 'In den nächsten Monaten',
                                'text' : 'In den nächsten Monaten'
                            },
                            {
                                'value' : 'In den nächsten Jahren',
                                'text' : 'In den nächsten Jahren'
                            },
                            {
                                'value' : 'Momentan nicht',
                                'text' : 'Momentan nicht'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Aus welchem Grund möchten Sie die Immobilie verkaufen?',
                        'key' : 'verkaufsgrund',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Alter/Rente',
                                'text' : 'Alter/Rente'
                            },
                            {
                                'value' : 'Erbe',
                                'text' : 'Erbe'
                            },
                            {
                                'value' : 'Scheidung',
                                'text' : 'Scheidung'
                            },
                            {
                                'value' : 'Marktsituation',
                                'text' : 'Marktsituation'
                            },
                            {
                                'value' : 'Umzug',
                                'text' : 'Umzug'
                            },
                            {
                                'value' : 'Anderer',
                                'text' : 'Anderer'
                            },
                        ],
                    },
                ]
            },
            {
                'component': 'ZipStep'
            },
            {
                'component': 'SearchStep'
            },
            {
                'component': 'DataStep',
                'title': 'Erhalten Sie jetzt Ihre individuelle Verkaufsempfehlung',
                'subtitle': 'Wer soll die Angebote erhalten?',
                'fields': [
                    'name',
                    'email',
                    'telefon'
                ],
                'btn_title' : 'Verkaufsempfehlung erhalten',
                'btn_subtitle' : 'kostenlos & unverbindlich',
                'head_notice' : 'Ihre kostenlose Verkaufsempfehlung wurde erstellt!',
            },
        ],

    };

    const immobilienConfigTeilverkauf = {
        'baseColor': '#005b36',
        'baseColorLight': '#007f4e',
        'baseColorText': '#ffffff',
        'html_title': 'Lohnt sich der Teilverkauf für Sie?',
        'type': 'TEILVERKAUF',
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.',
        ],
        'logo' : new URL('./../assets/imgs/types/immobilie/general/logo.png?width=300&format=jpeg', import.meta.url),
        'logo_big' : true,
        'bg_image' : new URL('./../assets/imgs/types/immobilie/general/bg.jpeg?width=2000&format=jpeg', import.meta.url),
        'bg_blur' : '5px',
        'bg_overlay_color': '#ffffff',
        'bg_overlay_opacity': 0.5,
        'bg_pos': 'center bottom',
        'bg_isDark': true,
        'steps': [
            {
                'component': 'Stepper',
                'title' : 'Lohnt sich der Teilverkauf für Sie?',
                'subtitle' : '',
                //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
                'steps' : [
                    {
                        'step_type': 'text',
                        'title' : 'Um welchen Immobilientyp geht es?',
                        'key' : 'type',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Haus',
                                'value' : 'Haus'
                            },
                            {
                                'text' : 'Wohnung',
                                'value' : 'Wohnung'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Um was für eine Art Haus handelt es sich?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'key' : 'haus_art',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Einfamilienhaus',
                                'value' : 'Einfamilienhaus'
                            },
                            {
                                'text' : 'Reihenhaus',
                                'value' : 'Reihenhaus'
                            },
                            {
                                'text' : 'Doppelhaushälfte',
                                'value' : 'Doppelhaushälfte'
                            },
                            {
                                'text' : 'Mehrfamilienhaus',
                                'value' : 'Mehrfamilienhaus'
                            },
                            {
                                'text' : 'Wohn-/Geschäftshaus',
                                'value' : 'Wohn-/Geschäftshaus'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Um was für eine Art Wohnung handelt es sich?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'key' : 'wohnung_art',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Erdgeschosswohnung',
                                'value' : 'Erdgeschosswohnung'
                            },
                            {
                                'text' : 'Etagenwohnung',
                                'value' : 'Etagenwohnung'
                            },
                            {
                                'text' : 'Dachgeschoss',
                                'value' : 'Dachgeschoss'
                            },
                            {
                                'text' : 'Andere',
                                'value' : 'Andere'
                            },

                        ],
                    },
                    {
                        'step_type': 'slider_simple',
                        'title' : 'Wie schätzen Sie Ihren Immobilienwert ein?',
                        'key' : 'teilverkauf_einschaetzung_wert',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 100000,
                        'max': 1000000,
                        'value': 300000,
                        'step': 1000,
                        'append_value': '€'
                    },
                    {
                        'step_type': 'slider_simple',
                        'title' : 'Welchen Betrag möchten Sie sich auszahlen lassen?',
                        'key' : 'teilverkauf_gewuenschte_auszahlung',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 100000,
                        'max': 500000,
                        'value': 150000,
                        'step': 1000,
                        'append_value': '€'
                    },
                    {
                        'step_type': 'slider_simple',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Wie groß ist die Wohnfläche der Immobilie?',
                        'key' : 'haus_flaeche_wohn',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 0,
                        'max': 500,
                        'value': 80,
                        'step': 1,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider_simple',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'title' : 'Wie groß ist die Wohnfläche der Immobilie?',
                        'key' : 'wohnung_flaeche',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 0,
                        'max': 500,
                        'value': 80,
                        'step': 1,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider_simple',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'title' : 'Wie groß ist die Grundstücksfläche?',
                        'key' : 'haus_flaeche_grund',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 0,
                        'max': 5000,
                        'value': 200,
                        'step': 1,
                        'append_value': 'm²'
                    },
                    {
                        'step_type': 'slider_simple',
                        'title' : 'Wie viele Zimmer hat die Immobilie?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'key' : 'haus_anzahl_zimmer',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 1,
                        'max': 15,
                        'value': 4,
                        'step': 1,
                        'append_value': ''
                    },
                    {
                        'step_type': 'slider_simple',
                        'title' : 'Wie viele Zimmer hat die Immobilie?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'key' : 'wohnung_zimmer',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 1,
                        'max': 15,
                        'value': 2,
                        'step': 1,
                        'append_value': ''
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie ist der Zustand der Immobilie?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'key' : 'zustand',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Neubau',
                                'value' : 'Neubau'
                            },
                            {
                                'text' : 'Gepflegt',
                                'value' : 'Gepflegt'
                            },
                            {
                                'text' : 'Renovierungsbedürftig',
                                'value' : 'Renovierungsbedürftig'
                            },
                            {
                                'text' : 'Sanierungsbedürftig',
                                'value' : 'Sanierungsbedürftig'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wie ist der Zustand der Immobilie?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'key' : 'zustand',
                        'multiple' : false,
                        'choices' : [
                            {
                                'text' : 'Neubau',
                                'value' : 'Neubau'
                            },
                            {
                                'text' : 'Gepflegt',
                                'value' : 'Gepflegt'
                            },
                            {
                                'text' : 'Renovierungsbedürftig',
                                'value' : 'Renovierungsbedürftig'
                            },
                            {
                                'text' : 'Sanierungsbedürftig',
                                'value' : 'Sanierungsbedürftig'
                            }
                        ],
                    },
                    {
                        'step_type': 'slider_simple',
                        'title' : 'Wann wurde die Immobilie ungefähr erbaut?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Haus'
                        },
                        'key' : 'haus_baujahr',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 1900,
                        'max': 2022,
                        'value': 1990,
                        'step': 1,
                        'append_value': ''
                    },
                    {
                        'step_type': 'slider_simple',
                        'title' : 'Wann wurde die Immobilie ungefähr erbaut?',
                        'condition':  {
                            'key' : 'type',
                            'value' : 'Wohnung'
                        },
                        'key' : 'wohnung_baujahr',
                        'icon' :  new URL('./../assets/imgs/types/immobilie/icons/set1/haus.png?width=200&format=png', import.meta.url),
                        'min': 1900,
                        'max': 2022,
                        'value': 1990,
                        'step': 1,
                        'append_value': ''
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Was ist Ihre Motivation zum Teilverkauf?',
                        'key' : 'teilverkauf_motivation',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Liquidität',
                                'text' : 'Liquidität'
                            },
                            {
                                'value' : 'Wünsche erfüllen',
                                'text' : 'Wünsche erfüllen'
                            },
                            {
                                'value' : 'Renovierung',
                                'text' : 'Renovierung'
                            },
                            {
                                'value': 'Schulden',
                                'text' : 'Schulden'
                            },
                            {
                                'value': 'Sonstiges',
                                'text' : 'Sonstiges'
                            }
                        ],
                    },
                    {
                        'step_type': 'text',
                        'title' : 'Wann können Sie sich einen Verkauf vorstellen?',
                        'key' : 'verkaufszeitpunkt',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Schnellstmöglich',
                                'text' : 'Schnellstmöglich'
                            },
                            {
                                'value' : 'In 1-3 Monaten',
                                'text' : 'In 1-3 Monaten'
                            },
                            {
                                'value' : 'In 3-6 Monaten',
                                'text' : 'In 3-6 Monaten'
                            },
                            {
                                'value' : 'In über 6 Monaten',
                                'text' : 'In über 6 Monaten'
                            }
                        ],
                    },
                ]
            },
            {
                'component': 'ZipStep'
            },
            {
                'component': 'SearchStep'
            },
            {
                'component': 'DataStep',
                'title': 'Erhalten Sie jetzt Ihre individuelle Verkaufsempfehlung',
                'subtitle': 'Wer soll die Angebote erhalten?',
                'fields': [
                    'name',
                    'email',
                    'telefon'
                ],
                'btn_title' : 'Verkaufsempfehlung erhalten',
                'btn_subtitle' : 'kostenlos & unverbindlich',
                'head_notice' : 'Ihre kostenlose Verkaufsempfehlung wurde erstellt!',
            },
            {
                'component': 'AfterSave',
            },
        ],

    };

    var treppenliftConfig = {
        'html_title': 'Treppenlift-Anbietervergleich',
        'type': 'TREPPENLIFT',
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.',
        ],
        'logo' : new URL('./../assets/imgs/types/treppenlift/logo.png?width=300&format=jpeg', import.meta.url),
        'bg_image' : new URL('./../assets/imgs/types/treppenlift/bg.jpg?width=2000&format=jpeg', import.meta.url),
        'bg_pos': 'center bottom',
        'bg_isDark': true,
        'bg_blur': '3px',
        'head_right' : [
            '<div class="lead-counter">Bereits <span>231.224 ✔</span><br/> Anfragen vermittelt</div>'
        ],
        steps : [
            {
                'component': 'Stepper',
                'title' : 'Treppenlift-Anbietervergleich',
                'subtitle' : 'Erhalten Sie kostenlose & unverbindliche Angebote aus Ihrer Region!*',
                //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
                'steps' : [

                    {
                        'step_type': 'image',
                        'title' : 'Was für einen Lift suchen Sie?',
                        'key' : 'liftart',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Sitzlift',
                                'text' : 'Sitzlift',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/10-sitzlift-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Plattformlift',
                                'text' : 'Plattformlift',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/10-plattformlift-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Hublift',
                                'text' : 'Hublift',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/10-hublift-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wo soll der Lift installiert werden?',
                        'key' : 'lift_position',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Innen',
                                'text' : 'Innen',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/20-innen-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Außen',
                                'text' : 'Außen',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/20-aussen-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Um was für ein Hindernis handelt es sich?',
                        'key' : 'hindernis',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Gerade Treppe',
                                'text' : 'Gerade Treppe',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/30-gerade-treppe-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Treppe mit Kurve',
                                'text' : 'Treppe mit Kurve',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/30-kurvige-treppe-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Sonstiges Hindernis',
                                'text' : 'Sonstiges Hindernis',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/30-sonstiges-hindernis-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Für wie viele Etagen ist der Lift gedacht?',
                        'key' : 'lift_etagen',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Bis zu 2',
                                'text' : 'Bis zu 2',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/40-bis-zu-2-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Mehr als 2',
                                'text' : 'Mehr als 2',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/40-mehr-als-2-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Sonstiges Hindernis',
                                'text' : 'Sonstiges Hindernis',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/40-sonstiges-hindernis-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Haben Sie einen Pflegegrad?',
                        'key' : 'pflegegrad',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Pflegegrad erteilt',
                                'text' : 'Pflegegrad erteilt',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/50-pflegegrad-erteilt-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Pflegegrad beantragt',
                                'text' : 'Pflegegrad beantragt',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/50-pflegegrad-beantragt-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Keinen Pflegegrad',
                                'text' : 'Keinen Pflegegrad',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/50-keinen-pflegegrad-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wann wird der Lift benötigt?', // todo only wenn dach ausgewählt
                        'key' : 'lieferzeit',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'So schnell wie möglich',
                                'text' : 'So schnell wie möglich',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/60-schnell-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'In 3-4 Monaten',
                                'text' : 'In 3-4 Monaten',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/60-1-2-monaten-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'In über 4 Monaten',
                                'text' : 'In über 4 Monaten',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/60-2-3-monaten-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Brauchen Sie Unterstützung bei der staatlichen Förderung?',
                        'key' : 'staatliche_foerderung',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/70-ja-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/70-nein-min.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon' :  new URL('./../assets/imgs/types/treppenlift/icons/fragezeichen.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    }
                ]
            },
            {
                'component': 'ZipStep'
            },
            {
                'component': 'SearchStep'
            },
            {
                'component': 'DataStep',
                'title': 'Erhalten Sie jetzt Ihre individuellen Angebote',
                'subtitle': 'Wer soll die Angebote erhalten?',
                'fields': [
                    'name',
                    'email',
                    'telefon'
                ],
                'btn_title' : 'Angebot erhalten',
                'btn_subtitle' : 'kostenlos & unverbindlich',
                'head_notice' : 'Wir haben 3 Fachfirmen für Sie ermittelt!',
            },
            {
                'component': 'AfterSave',
                'cross_selling' : true,
                'cross_selling_options': {
                    'title': 'Könnte für Sie auch ein anderes Angebot von uns interessant sein?',
                    'ad_type': 'AdImageBox',
                    'number_of_ads': 3,
                    'ad_items': [
                        {
                            'text': 'Hausnotruf',
                            'image': new URL('./../assets/imgs/cross_selling/hausnotruf.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Hausnotruf',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Seniorenbetreuung',
                            'image': new URL('./../assets/imgs/cross_selling/seniorenbetreuung.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Seniorenbetreuung',
                            'text_position': 'bottom',
                        },
                        {
                            'text': 'Elektromobil',
                            'image': new URL('./../assets/imgs/cross_selling/elektromobil.jpg?width=300&format=jpeg', import.meta.url),
                            'value': 'Elektromobil',
                            'text_position': 'bottom',
                        },
                    ]
                },
            },

        ]
    };

    var solarConfig = {
        'html_title': 'Einfach zur Solaranlage',
        'type': 'SOLARANLAGE',
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.',
        ],
        'logo' : new URL('./../assets/imgs/types/solaranlage/logo.png?width=300&format=jpeg', import.meta.url),
        'bg_image' : new URL('./../assets/imgs/types/solaranlage/bg.jpg?width=2000&format=jpeg', import.meta.url),
        'bg_pos': 'center bottom',
        'bg_isDark': true,
        'head_right' : [
            '<div class="lead-counter">Bereits <span>51.384</span><br/> Anfragen vermittelt</div>',
            '<div class="lead-counter">Mehr als <span>200</span><br/> Anbieter</div>'
        ],
        'enable_direct_partner': false,




        //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
        'steps': [
            {
                'component': 'Stepper',
                'title' : 'Photovoltaik lohnt sich wieder!',
                'subtitle' : 'Bis zu 4 Photovoltaik-Anbieter in Ihrer Region finden.*',
                //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
                'steps' : [
                    {
                        'step_type': 'image',
                        'title' : 'Wo möchten Sie die Photovoltaik installieren?',
                        'key' : 'installationsort',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ein-Zweifamilienhaus',
                                'text' : 'Ein-Zweifamilienhaus',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/privathaushalt.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Mehrfamilienhaus',
                                'text' : 'Mehrfamilienhaus',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/mehrfamilienhaus.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Firmengebäude',
                                'text' : 'Firmengebäude',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/gewerbe_3.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Freilandfläche',
                                'text' : 'Freilandfläche',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/freilandflache.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Sonstiges',
                                'text' : 'Sonstiges',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/sonstiges.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wie groß ist die Fläche bzw. die geplante Anlage?',
                        'key' : 'flaeche',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Bis 30qm',
                                'text' : 'Bis 30qm',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/bis_30qm.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '30 bis 100 qm',
                                'text' : '30 bis 100 qm',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/30_bis_100qm.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '101 bis 200 qm',
                                'text' : '101 bis 200 qm',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/101_bis_200qm.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Über 200 qm',
                                'text' : 'Über 200 qm',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/ueber_200qm.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wie wollen Sie die Photovoltaik nutzen?',
                        'key' : 'nutzen',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Netzeinspeisung',
                                'text' : 'Netzeinspeisung',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/netzeinspeisung.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Eigenverbrauch',
                                'text' : 'Eigenverbrauch',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/eigenverbrauch.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Beides',
                                'text' : 'Beides',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/beides.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Welche Dachform hat Ihr Haus?',
                        'key' : 'dachform',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Satteldach',
                                'text' : 'Satteldach',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/sattledach.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Pultdach',
                                'text' : 'Pultdach',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/pultdach.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Flachdach',
                                'text' : 'Flachdach',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/flachdach.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Sonstiges',
                                'text' : 'Sonstiges',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/sonstiges.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Gibt es Verschattung auf dem Dach?',
                        'key' : 'verschattung',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Nein',
                                'text' : 'Nein',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/nein.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Ja',
                                'text' : 'Ja',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/ja.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß  nicht',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/sonstiges.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Möchten Sie die Solaranlagen durch einen Stromspeicher ergänzen?',
                        'key' : 'stromspeicher',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/solarspeicher_ja.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/solarspeicher_nein.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß  nicht',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/sonstiges.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Welches Finanzierungsmodell kommt für Sie in Frage?',
                        'key' : 'finanzierungsmodell',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Beides interessant',
                                'text' : 'Beides interessant',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/sonstiges.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Mieten',
                                'text' : 'Mieten',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/meiten.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Kaufen',
                                'text' : 'Kaufen',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/kaufen.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wann soll das Projekt umgesetzt werden?',
                        'key' : 'umsetzung_zeitraum',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Umgehend',
                                'text' : 'Umgehend',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/kalender-icon-umgehend.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'In 1 - 3 Monaten',
                                'text' : 'In 1 - 3 Monaten',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/kalender-icon-1bis3monate.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'In 3 - 6 Monaten',
                                'text' : 'In 3 - 6 Monaten',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/kalender-icon-4bis6monate.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Länger als 6 Monate',
                                'text' : 'Länger als 6 Monate',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/kalender-icon-6monate.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Sind Sie an einem günstigen Stromtarif interessiert?',
                        'key' : 'interesse_stromtarif',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/icon-ja-general.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/icon-nein-general.png?width=200&format=png', import.meta.url)
                            },
                            ],
                    },
                ]
            },
            {
                'component': 'ZipStep'
            },
            {
                'component': 'SearchStep',
                'price_threshold' : 120,
            },
            {
                'component': 'DataStep',
                'title': 'Erhalten Sie jetzt Ihre individuellen Angebote',
                'subtitle': 'Wer soll die Angebote erhalten?',
                'fields': [
                    'name',
                    'email',
                    'telefon'
                ],
                'btn_title' : 'Angebot erhalten',
                'btn_subtitle' : 'kostenlos & unverbindlich',
                'head_notice' : 'Wir haben 4 Fachfirmen für Sie ermittelt!',
                'direct_partner_optin': "Ich willige hiermit ein, dass meine Daten zur Angebotsvermittlung an die Interlead GmbH (Konsul-Smidt-Str. 8L, 28217 Bremen) weitergibt und erteile diesen die Erlaubnis, mich zum Zwecke einer Beratung per E-Mail und/oder telefonisch zu kontaktieren."
            },
            {
                'component': 'AfterSave',
            },






        ],

    };

    var solarConfigAT = {
        'html_title': 'Einfach zur Solaranlage',
        'type': 'SOLARANLAGE',
        'target_country': 'AT',
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.',
        ],
        'logo' : new URL('./../assets/imgs/types/solaranlage/logo.png?width=300&format=jpeg', import.meta.url),
        'bg_image' : new URL('./../assets/imgs/types/solaranlage/bg.jpg?width=2000&format=jpeg', import.meta.url),
        'bg_pos': 'center bottom',
        'bg_isDark': true,
        'head_right' : [
            '<div class="lead-counter">Bereits <span>51.384</span><br/> Anfragen vermittelt</div>',
            '<div class="lead-counter">Mehr als <span>200</span><br/> Anbieter</div>'
        ],




        //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
        'steps': [
            {
                'component': 'Stepper',
                'title' : 'Photovoltaik lohnt sich wieder!',
                'subtitle' : 'Bis zu 4 Photovoltaik-Anbieter in Ihrer Region finden.*',
                //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
                'steps' : [
                    {
                        'step_type': 'image',
                        'title' : 'Wo möchten Sie die Photovoltaik installieren?',
                        'key' : 'installationsort',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ein-Zweifamilienhaus',
                                'text' : 'Ein-Zweifamilienhaus',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/privathaushalt.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Mehrfamilienhaus',
                                'text' : 'Mehrfamilienhaus',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/mehrfamilienhaus.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Firmengebäude',
                                'text' : 'Firmengebäude',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/gewerbe_3.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Freilandfläche',
                                'text' : 'Freilandfläche',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/freilandflache.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Sonstiges',
                                'text' : 'Sonstiges',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/sonstiges.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wie groß ist die Fläche bzw. die geplante Anlage?',
                        'key' : 'flaeche',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Bis 30qm',
                                'text' : 'Bis 30qm',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/bis_30qm.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '30 bis 100 qm',
                                'text' : '30 bis 100 qm',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/30_bis_100qm.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '101 bis 200 qm',
                                'text' : '101 bis 200 qm',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/101_bis_200qm.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Über 200 qm',
                                'text' : 'Über 200 qm',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/ueber_200qm.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wie wollen Sie die Photovoltaik nutzen?',
                        'key' : 'nutzen',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Netzeinspeisung',
                                'text' : 'Netzeinspeisung',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/netzeinspeisung.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Eigenverbrauch',
                                'text' : 'Eigenverbrauch',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/eigenverbrauch.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Beides',
                                'text' : 'Beides',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/beides.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Welche Dachform hat Ihr Haus?',
                        'key' : 'dachform',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Satteldach',
                                'text' : 'Satteldach',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/sattledach.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Pultdach',
                                'text' : 'Pultdach',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/pultdach.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Flachdach',
                                'text' : 'Flachdach',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/flachdach.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Sonstiges',
                                'text' : 'Sonstiges',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/sonstiges.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Gibt es Verschattung auf dem Dach?',
                        'key' : 'verschattung',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Nein',
                                'text' : 'Nein',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/nein.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Ja',
                                'text' : 'Ja',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/ja.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß  nicht',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/sonstiges.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Möchten Sie die Solaranlagen durch einen Stromspeicher ergänzen?',
                        'key' : 'stromspeicher',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/solarspeicher_ja.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/solarspeicher_nein.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß  nicht',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/sonstiges.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Welches Finanzierungsmodell kommt für Sie in Frage?',
                        'key' : 'finanzierungsmodell',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Beides interessant',
                                'text' : 'Beides interessant',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/sonstiges.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Mieten',
                                'text' : 'Mieten',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/meiten.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Kaufen',
                                'text' : 'Kaufen',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/kaufen.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wann soll das Projekt umgesetzt werden?',
                        'key' : 'umsetzung_zeitraum',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Umgehend',
                                'text' : 'Umgehend',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/kalender-icon-umgehend.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'In 1 - 3 Monaten',
                                'text' : 'In 1 - 3 Monaten',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/kalender-icon-1bis3monate.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'In 3 - 6 Monaten',
                                'text' : 'In 3 - 6 Monaten',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/kalender-icon-4bis6monate.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Länger als 6 Monate',
                                'text' : 'Länger als 6 Monate',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/kalender-icon-6monate.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Sind Sie an einem günstigen Stromtarif interessiert?',
                        'key' : 'interesse_stromtarif',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja',
                                'text' : 'Ja',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/icon-ja-general.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nein',
                                'text' : 'Nein',
                                'icon' :  new URL('./../assets/imgs/types/solaranlage/icons/icon-nein-general.png?width=200&format=png', import.meta.url)
                            },
                        ],
                    },
                ]
            },
            {
                'component': 'ZipStep'
            },
            {
                'component': 'SearchStep'
            },
            {
                'component': 'DataStep',
                'title': 'Erhalten Sie jetzt Ihre individuellen Angebote',
                'subtitle': 'Wer soll die Angebote erhalten?',
                'fields': [
                    'name',
                    'email',
                    'telefon'
                ],
                'btn_title' : 'Angebot erhalten',
                'btn_subtitle' : 'kostenlos & unverbindlich',
                'head_notice' : 'Wir haben 4 Fachfirmen für Sie ermittelt!',
            },
            {
                'component': 'AfterSave',
            },






        ],

    };

    var solarConfigDebug = {
        'html_title': 'Einfach zur Solaranlage',
        'type': 'SOLARANLAGE',
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.',
        ],
        'logo' : new URL('./../assets/imgs/types/solaranlage/logo.png?width=300&format=jpeg', import.meta.url),
        'bg_image' : new URL('./../assets/imgs/types/solaranlage/bg.jpg?width=2000&format=jpeg', import.meta.url),
        'bg_pos': 'center bottom',
        'bg_isDark': true,
        'head_right' : [
            '<div class="lead-counter">Bereits <span>51.384</span><br/> Anfragen vermittelt</div>',
            '<div class="lead-counter">Mehr als <span>200</span><br/> Anbieter</div>'
        ],
        'enable_direct_partner': false,
        'keyword_box': {
            'enabled': true,
            'debug': true,
            'title': 'Was möchten Sie wissen?',
            'text': 'Das ist Test Text',
            'keyword_box_script_url': 'https://delivery.adsolutely.com/box.js?o=018b8b87-5e2b-7e22-baa9-88e11d91061e',
            'sodad_id': '0190fe68-a144-7da1-82c7-787d84ef9d52',
        },
        'steps': [
            {
                'component': 'ZipStep'
            },
            {
                'component': 'SearchStep',
                'price_threshold' : 120,
            },
            {
                'component': 'DataStep',
                'title': 'Erhalten Sie jetzt Ihre individuellen Angebote',
                'subtitle': 'Wer soll die Angebote erhalten?',
                'fields': [
                    'name',
                    'email',
                    'telefon'
                ],
                'btn_title' : 'Angebot erhalten',
                'btn_subtitle' : 'kostenlos & unverbindlich',
                'head_notice' : 'Wir haben 4 Fachfirmen für Sie ermittelt!',
                'direct_partner_optin': "Ich willige hiermit ein, dass meine Daten zur Angebotsvermittlung an die Interlead GmbH (Konsul-Smidt-Str. 8L, 28217 Bremen) weitergibt und erteile diesen die Erlaubnis, mich zum Zwecke einer Beratung per E-Mail und/oder telefonisch zu kontaktieren."
            },
            {
                'component': 'AfterSave',
            },






        ],

    };

    var waermepumpeConfig = {
        'html_title': 'Einfach zur Wärmepumpe',
        'type': 'WAERMEPUMPE',
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.',
        ],
        'logo' : new URL('./../assets/imgs/types/waermepumpe/logo_placeholder.png?format=png', import.meta.url),
        'bg_image' : new URL('./../assets/imgs/types/waermepumpe/bg.jpg?width=2000&format=jpeg', import.meta.url),
        'bg_pos': 'center top',
        'bg_isDark': true,
        'head_right' : [
            '<div class="lead-counter">Bereits <span>51.384</span><br/> Anfragen vermittelt</div>',
            '<div class="lead-counter">Mehr als <span>200</span><br/> Anbieter</div>'
        ],




        //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
        'steps': [
            {
                'component': 'Stepper',
                'title' : 'Wärmepumpe-Anbietervergleich',
                'subtitle' : 'Kostenlos & unverbindlich bis zu 4 Angebote vergleichen.*',
                //'head_notice' : 'Bis zu 60% sparen gegenüber Wasserflaschen**',
                'steps' : [
                    {
                        'step_type': 'image',
                        'title' : 'Wo soll die Wärmepumpe installiert werden?',
                        'key' : 'installationsort',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ein-Zweifamilienhaus',
                                'text' : 'Ein-Zweifamilienhaus',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/01_01.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Mehrfamilienhaus',
                                'text' : 'Mehrfamilienhaus',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/01_02.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Firmengebäude',
                                'text' : 'Firmengebäude',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/01_03.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Sonstiges',
                                'text' : 'Sonstiges',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/01_04.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Womit heizen Sie aktuell?',
                        'key' : 'heizungsart_aktuell',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Öl / Gas',
                                'text' : 'Öl / Gas',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/02_01.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Holz / Pellets',
                                'text' : 'Holz / Pellets',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/02_02.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Strom',
                                'text' : 'Strom',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/02_03.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Sonstiges / Weiß ich nicht',
                                'text' : 'Sonstiges / Weiß ich nicht',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/02_04.png?width=200&format=png', import.meta.url)
                            },
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wie groß ist Ihre beheizte Wohnfläche ca?',
                        'key' : 'beheizte_flaeche',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Bis 50qm',
                                'text' : 'Bis 50qm',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/03_01.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '50 bis 100 qm',
                                'text' : '50 bis 100 qm',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/03_02.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : '100 bis 200 qm',
                                'text' : '100 bis 200 qm',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/03_03.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Mehr als 200 qm',
                                'text' : 'Mehr als 200 qm',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/03_04.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Welches Wärmeabgabesystem soll verwendet / eingebaut werden?',
                        'key' : 'waermeabgabesystem',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Fußboden',
                                'text' : 'Fußboden',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/04_01.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Heizkörper',
                                'text' : 'Heizkörper',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/04_02.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Beides',
                                'text' : 'Beides',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/04_03.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Hierzu wünsche ich eine Beratung',
                                'text' : 'Hierzu wünsche ich eine Beratung',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/04_04.png?width=200&format=png', import.meta.url)
                            },
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Haben Sie Interesse an einer Photovoltaikanlage?',
                        'key' : 'interesse_photovoltaikanlage',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Ja bitte',
                                'text' : 'Ja bitte',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/05_01.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Nein danke',
                                'text' : 'Nein danke',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/05_02.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Bitte Beratung',
                                'text' : 'Bitte Beratung',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/05_03.png?width=200&format=png', import.meta.url)
                            },
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wann sollen wir mit dem Heizungseinbau beginnen?',
                        'key' : 'beginn_heizungseinbau',
                        'multiple' : false,
                        'choices' : [
                            {
                                'value' : 'Umgehend',
                                'text' : 'Umgehend',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/06_01.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'In 1 - 3 Monaten',
                                'text' : 'In 1 - 3 Monaten',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/06_02.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'In 3 - 6 Monaten',
                                'text' : 'In 3 - 6 Monaten',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/06_03.jpg?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Länger als 6 Monate',
                                'text' : 'Länger als 6 Monate',
                                'icon' :  new URL('./../assets/imgs/types/waermepumpe/icons/06_04.jpg?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                ]
            },
            {
                'component': 'ZipStep',
                'title' : 'Wo befindet sich Ihre Immobilie? - Damit wir in Ihrer Region passende Anbieter finden können',
            },
            {
                'component': 'SearchStep'
            },
            {
                'component': 'DataStep',
                'title': 'Erhalten Sie jetzt Ihre individuellen Angebote',
                'subtitle': 'Wer soll die Angebote erhalten?',
                'fields': [
                    'name',
                    'email',
                    'telefon'
                ],
                'btn_title' : 'Angebot erhalten',
                'btn_subtitle' : 'kostenlos & unverbindlich',
                'head_notice' : 'Wir haben 4 Fachfirmen für Sie ermittelt!',
            },
            {
                'component': 'AfterSave',
            },






        ],

    };

    let elektrorollstuhlConfig = {
        'html_title': 'Einfach zum Elektrorollstuhl',
        'type': 'ELEKTROROLLSTUHL',
        'footer_notice' : [
            '*Der Service ist für Sie komplett kostenfrei und unverbindlich. Wir finanzieren den Service über die Provision unserer Anbieter.',
        ],
        'logo' : new URL('./../assets/imgs/types/elektrorollstuhl/logo.png?format=png', import.meta.url),
        'bg_image' : new URL('./../assets/imgs/types/elektrorollstuhl/bg.png?width=2000&format=jpeg', import.meta.url),
        'bg_pos': 'center',
        'bg_isDark': true,
        'head_right' : [
            '<div class="lead-counter">Bereits <span>51.384</span><br/> Anfragen vermittelt</div>',
            '<div class="lead-counter">Mehr als <span>200</span><br/> Anbieter</div>'
        ],
        steps: [
            {
                'component': 'Stepper',
                'title' : 'Elektrorollstuhl: Mobil im Alltag',
                'subtitle' : 'Jetzt mit Rezept zu 100% kostenlos',
                steps: [
                    {
                        'step_type': 'image',
                        'title' : 'Für welches Modell interessieren Sie sich?',
                        'key' : 'modell_typ',
                        'multiple' : false,
                        'choices': [
                            {
                                'value' : 'Elektrorollstuhl',
                                'text' : 'Elektrorollstuhl',
                                'icon': new URL('./../assets/imgs/types/elektrorollstuhl/19.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value' : 'Weiß nicht',
                                'text' : 'Weiß nicht',
                                'icon': new URL('./../assets/imgs/types/elektrorollstuhl/2.png?width=200&format=png', import.meta.url)
                            }
                        ]
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Für welches Gewicht muss der Elektrorollstuhl geeignet sein?',
                        'key': 'rollstuhl_nutzer_gewicht',
                        'multiple': false,
                        'choices': [
                            {
                                'value': 'Unter 145 KG',
                                'text': 'Unter 145 KG',
                                'icon': new URL('./../assets/imgs/types/elektrorollstuhl/6.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value': 'Über 145 KG',
                                'text': 'Über 145 KG',
                                'icon': new URL('./../assets/imgs/types/elektrorollstuhl/8.png?width=200&format=png', import.meta.url)
                            }
                        ]
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wer soll den Elektrorollstuhl nutzen?',
                        'key': 'rollstuhl_nutzer',
                        'multiple': false,
                        'choices': [
                            {
                                'value': 'Ich',
                                'text': 'Ich',
                                'icon': new URL('./../assets/imgs/types/elektrorollstuhl/10.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value': '(Ehe-)Partner',
                                'text': '(Ehe-)Partner',
                                'icon': new URL('./../assets/imgs/types/elektrorollstuhl/11.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value': 'Familienangehörige',
                                'text': 'Familienangehörige',
                                'icon': new URL('./../assets/imgs/types/elektrorollstuhl/12.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value': 'Sonstige Person',
                                'text': 'Sonstige Person',
                                'icon': new URL('./../assets/imgs/types/elektrorollstuhl/10.png?width=200&format=png', import.meta.url)
                            }
                            ]
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Ist eine kostenlose Probefahrt gewünscht?',
                        'key': 'probefahrt_erwuenscht',
                        'multiple': false,
                        'choices': [
                            {
                                'value': 'Ja',
                                'text': 'Ja',
                                'icon': new URL('./../assets/imgs/types/elektrorollstuhl/13.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value': 'Nein',
                                'text': 'Nein',
                                'icon': new URL('./../assets/imgs/types/elektrorollstuhl/14.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'step_type': 'image',
                        'title' : 'Wird Unterstützung bei der Genehmigung des Rezepts benötigt?',
                        'key': 'hilfe_genehmigung_rezept',
                        'multiple': false,
                        'choices': [
                            {
                                'value': 'Ja',
                                'text': 'Ja',
                                'icon': new URL('./../assets/imgs/types/elektrorollstuhl/13.png?width=200&format=png', import.meta.url)
                            },
                            {
                                'value': 'Nein',
                                'text': 'Nein',
                                'icon': new URL('./../assets/imgs/types/elektrorollstuhl/14.png?width=200&format=png', import.meta.url)
                            }
                        ],
                    },
                    {
                        'component': 'ZipStep'
                    },
                    {
                        'component': 'SearchStep'
                    },
                    {
                        'component': 'DataStep',
                        'title': 'Wer soll die Angebote erhalten?',
                        'fields': [
                            'name',
                            'street',
                            'email',
                            'telefon'
                        ],
                        'btn_title' : 'Vergleichen & sparen',
                        'btn_subtitle' : 'kostenlos & unverbindlich',
                    },
                    {
                        'component': 'AfterSave',
                    },
                ]
            }
        ]
    }


    let allConfigs = {
        'aufzugswartung': {
          'vergleichen': aufzugswartungConfig
        },
        'baufinanzierung': {
            'vergleichen': baufinanzierungConfig
        },
        'kaffeevollautomat': {
            'vergleichen': kaffeevollautomatConfig,
        },
        'kaffeevollautomatAT': {
            'vergleichen': kaffeevollautomatConfigAT
        },
        'elektrorollstuhl': {
            'vergleichen': elektrorollstuhlConfig
        },
        'immobilie': {
            'text': immobilienConfigText,
            'teilverkauf': immobilienConfigTeilverkauf,
            'vergleichen': immobilienConfig,
            'vergleich': immobilienConfigMap
        },
        'terrassendach': {
            'vergleichen': terrassendachConfig,
        },
        'wasserspender': {
            'vergleichen': wasserspenderConfig
        },
        'treppenlift': {
            'vergleichen': treppenliftConfig
        },
        'solaranlage': {
            'vergleichen': solarConfig,
            'debug': solarConfigDebug
        },
        'solaranlageAT': {
            'vergleichen': solarConfigAT
        },
        'waermepumpe': {
            'vergleichen': waermepumpeConfig
        },
    }

    for(let type in allConfigs){
        for(let url in allConfigs[type]){
            if(allConfigs[type][url]['baseColor'] === undefined){
                allConfigs[type][url]['baseColor'] = '#ff9600';
                allConfigs[type][url]['baseColorText'] = '#ffffff';
            }

        }
    }

    /*
    for(let i in allConfigs){

        if(i != 'kaffeevollautomat') continue;

        let config = allConfigs[i];


        console.log('impurt url',config['bg_image'] );
        config['bg_image'] = (require(config['bg_image'])).default;
    }

     */

    return allConfigs;
}

function getSetDescendantProp(obj, desc, value) {
    var arr = desc ? desc.split(".") : [];

    while (arr.length && obj) {
        var comp = arr.shift();
        var match = new RegExp("(.+)\\[([0-9]*)\\]").exec(comp);

        // handle arrays
        if ((match !== null) && (match.length == 3)) {
            var arrayData = {
                arrName: match[1],
                arrIndex: match[2]
            };
            if (obj[arrayData.arrName] !== undefined) {
                if (typeof value !== 'undefined' && arr.length === 0) {
                    obj[arrayData.arrName][arrayData.arrIndex] = value;
                }
                obj = obj[arrayData.arrName][arrayData.arrIndex];
            } else {
                obj = undefined;
            }

            continue;
        }

        // handle regular things
        if (typeof value !== 'undefined') {
            if (obj[comp] === undefined) {
                obj[comp] = {};
            }

            if (arr.length === 0) {
                obj[comp] = value;
            }
        }

        obj = obj[comp];
    }

    return obj;
}
function getDescendantProp(obj, desc) {
    var arr = desc.split(".");
    while(arr.length && (obj = obj[arr.shift()]));
    return obj;
}


class ConfigLoader {

    static getDefaultDevType(){
        return 'immobilie';
    }

    static async loadConfigObj(){
        let allConfigs = await getAllConfigs();

        let type = null;
        let typeSite = null;



        if(window.location.host == 'einfach-zum-angebot.de' || window.location.host == 'www.einfach-zum-angebot.de' || window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('127.0.0.1') > -1 || window.location.host.indexOf('ngrok') > -1){
            let pathes = window.location.pathname.split('/');



            if(pathes[2] && allConfigs[pathes[2]] && pathes[3] && allConfigs[pathes[2]][pathes[3]]){
                type = pathes[2];
                typeSite = pathes[3];
            } else if(pathes[2] && allConfigs[pathes[2]]){
                for(let k in allConfigs[pathes[2]]){
                    type = pathes[2];
                    typeSite = k;
                    break;
                }
            }


        } else if(window.location.host == 'verkaufe-deine-immobilie.net' || window.location.host == 'www.verkaufe-deine-immobilie.net'){
            type = 'immobilie';

        } else if(window.location.host == 'einfach-zur-solaranlage.de' || window.location.host == 'www.einfach-zur-solaranlage.de'){
            type = 'solaranlage';
        } else if(window.location.host == 'einfach-zur-solaranlage.at' || window.location.host == 'www.einfach-zur-solaranlage.at'){
            type = 'solaranlageAT';
        } else if(window.location.host == 'treppenlift-hauslift.de' || window.location.host == 'www.treppenlift-hauslift.de'){
            type = 'treppenlift';
        } else if( (window.location.host == 'kaffeevollautomaten-online.de' || window.location.host == 'www.kaffeevollautomaten-online.de')){
            type = 'kaffeevollautomat';
        } else if( (window.location.host == 'kaffeevollautomaten-online.at' || window.location.host == 'www.kaffeevollautomaten-online.at')){
            type = 'kaffeevollautomatAT';
        } else if( (window.location.host == 'terrassendach-kaufen.net' || window.location.host == 'www.terrassendach-kaufen.net')){
            type = 'terrassendach';
        } else if( (window.location.host == 'xn--einfach-zur-wrmepumpe-i2b.de' || window.location.host == 'www.xn--einfach-zur-wrmepumpe-i2b.de' || window.location.host == 'einfach-zur-waermepumpe.de' || window.location.host == 'www.einfach-zur-waermepumpe.de')){
            type = 'waermepumpe';
        } else if(window.location.host == 'einfach-zum-elektrorollstuhl.de' || window.location.host == 'www.einfach-zum-elektrorollstuhl.de'){
            type = 'elektrorollstuhl';
        } else {
            alert('No configured Site');
            return null;
        }

        console.log('type', type, typeSite);

        let additionalData = {};


        switch(type){
            case 'immobilie':
                additionalData.gtmId = 'GTM-5WJDFFW';
                additionalData.gaId = 'UA-79772027-5';

                break;

            case 'solaranlage':
                additionalData.gtmId = 'GTM-NDWSHNL';
                additionalData.nativendoId = 'MuMK_zhBqZwm7zqQOPF3kJgQ0Zn56aMnpNHs9Kt-3gqtZIszrlaH7JBVlvfGV8p-eXGng8GE8zKf578uzTzH-PmKW-OSXn2mX3dofMYzSKc';

                break;

            case 'treppenlift':
                additionalData.gtmId = 'GTM-MTJBMMX';

                break;


            case 'kaffeevollautomat':
                additionalData.gtmId = 'GTM-KF6V4F6';

                break;
        }


        console.log('found type', type, typeSite);



        if(type && !typeSite){
            let pathes = window.location.pathname.split('/');

            if(pathes[2] && allConfigs[type][pathes[2]]){
                return Object.assign({}, allConfigs[type][pathes[2]], additionalData);
            }


            for(let k in allConfigs[type]){
                return Object.assign({}, allConfigs[type][k], additionalData);
            }
        } else if(type && typeSite){
            return Object.assign({}, allConfigs[type][typeSite], additionalData);
        }



        // default

        return Object.assign({}, allConfigs[type]['vergleichen'], additionalData);

    }

    static async loadConfig(){
        let config = await ConfigLoader.loadConfigObj();



        let query = document.location.search.substr(1);
        let queries = query.split('&');

        queries.forEach((q)=>{
            let parts = q.split('=');
            let k = decodeURIComponent(parts[0]);
            let v = decodeURIComponent(parts[1]);


            getSetDescendantProp(config, k, v);
        });



        return config;
    }

    static async getAllConfigs(){
        return await getAllConfigs();
    }
}

export default ConfigLoader;
