<template>
  <div class="app">
    <iframe v-if="shouldEmbedIframe" :src="iframeSrc" style="position:absolute;left:-9999px"></iframe>
    <SimpleLayout :config="config" v-if="config" />
    <KeywordModal v-if="config && config?.keyword_box?.enabled" :config="config" />
  </div>
</template>

<script>
import SimpleLayout from './components/layouts/SimpleLayout.vue'
import ConfigLoader from './helpers/ConfigLoader.js'
import {ref} from "vue";
import KeywordModal from "@/components/ads/KeywordModal.vue";

export default {
  name: 'App',
  components: {
    KeywordModal,
    SimpleLayout,
  },
  mounted(){
    const data = ref(null);
    var config = ConfigLoader.loadConfig().then((config)=>{
      console.log('loadedConfig', config);
      this.config = config;

      const urlParams = new URLSearchParams(window.location.search);
      const utmSource = urlParams.get('utm_source');
      const token = urlParams.get('token');


      // Check if the conditions are met
      if (utmSource === 'cf' && token) {
        this.shouldEmbedIframe = true;
        this.iframeSrc = `https://cfpixl.blue/cf394-lp?token=${token}`;
      }

      let $title = window.document.querySelector('html head title');
      if(this.config.html_title){
        $title.innerHTML = this.config.html_title;
      } else {
        $title.innerHTML = 'Einfach zum Angebot';
      }


      if(config.gtmId){
        this.loadGTM(config);
      }
      if(config.gaId){
        this.loadGA(config);
      }
      if(config?.keyword_box?.enabled){
        this.loadKeywordBox(config);
      }
    });

  },
  methods: {
    loadGTM(config){
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':

            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],

          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=

          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);

      })(window,document,'script','dataLayer',config.gtmId);
      console.log('LOAD GTM', config.gtmId);
    },
    loadGA(config){
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

      console.log('LOAD Tracking GA', config.gaId);
      ga('create', config.gaId, 'auto');
      ga('send', 'pageview');
    },
    loadKeywordBox(config){
      if(!config?.keyword_box?.keyword_box_script_url){
        return;
      }
      let script = document.createElement('script');
      script.src = config.keyword_box.keyword_box_script_url;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    },
  },
  data() {

    return {
      config: null,
      shouldEmbedIframe: false,  // new data property
      iframeSrc: '',
    }
  },
}
</script>

<style>

  @import "~@vueform/slider/themes/default.css";

  .app {
    --header-bg-color: white;
    --header-count-notice-color: #444444;

    --header-notice-color: white;
    --features-bg-color: #d4dce0;
    --features-color: black;
    --footer-notice-bg-color: transparent;
    --footer-notice-color: #7D7D7D;

    --footer-bg-color: #263238;
    --footer-color: white;
    --header-notice-bg-color: v-bind('(config && config.baseColor) ? config.baseColor : "#ff9600"');
    --default-border-radius: 5px;

  }

  body {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    padding: 0;
    margin: 0;

  }

  .btn {
    padding: 15px;
    border: 0;
    min-width: 200px;
    font-size: 16px;
    font-weight: bold;
    border-radius: var(--default-border-radius);
    cursor: pointer;
  }

  .btn--notice {
    background: var(--header-notice-bg-color);
    color: white;
  }

  .input {
    padding: 15px 10px;
    border-radius: var(--default-border-radius);
    border: 1px solid #a0a6a9;
    font-size: 1em;
  }
  .card {
    background: white;
    max-width: 100%;
    margin: auto;
    padding: 40px;
  }




</style>
